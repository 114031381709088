/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 20, 2017 */


/* Lobster */

@font-face {
    font-family: 'lobster_regular';
    src: url('../fonts/lobster-webfont.eot');
    src: url('../fonts/lobster-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lobster-webfont.woff2') format('woff2'),
         url('../fonts/lobster-webfont.woff') format('woff'),
         url('../fonts/lobster-webfont.ttf') format('truetype'),
         url('../fonts/lobster-webfont.svg#lobster_1.4regular') format('svg');
    font-weight: normal;
    font-style: normal;

}


/* Montserrat */


@font-face {
    font-family: 'montserratextralight';
    src: url('../fonts/montserrat-extralight-webfont.eot');
    src: url('../fonts/montserrat-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extralight-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extralight-webfont.woff') format('woff'),
         url('../fonts/montserrat-extralight-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-extralight-webfont.svg#montserratextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratlight';
    src: url('../fonts/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat-light-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}


/* Open Sans */


@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}


/* Work Sans */


@font-face {
    font-family: 'work_sansbold';
    src: url('../fonts/worksans-bold-webfont.eot');
    src: url('../fonts/worksans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans-bold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-bold-webfont.woff') format('woff'),
         url('../fonts/worksans-bold-webfont.ttf') format('truetype'),
         url('../fonts/worksans-bold-webfont.svg#work_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'work_sanslight';
    src: url('../fonts/worksans-light-webfont.eot');
    src: url('../fonts/worksans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans-light-webfont.woff2') format('woff2'),
         url('../fonts/worksans-light-webfont.woff') format('woff'),
         url('../fonts/worksans-light-webfont.ttf') format('truetype'),
         url('../fonts/worksans-light-webfont.svg#work_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'work_sanssemibold';
    src: url('../fonts/worksans-semibold-webfont.eot');
    src: url('../fonts/worksans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-semibold-webfont.woff') format('woff'),
         url('../fonts/worksans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/worksans-semibold-webfont.svg#work_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}