$icon-font-path: '../fonts/';
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "_variables.scss";
@import "_fonts.scss";

.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}


/* Space out content a bit */

/* css général */

/* contenu rédactionnel */

h1 {
	font-family: $p_montserrat_light;
	color: $black_blue;
	font-size: $pfs_contenuR_h1;
	text-align: left;

	strong {
		font-family: $p_montserrat_bold;
	}

	span {
		font-family: $p_montserrat_bold;
	}
}

h2 {
	font-family: $p_montserrat_light;
	color: $black_blue;
	font-size: $pfs_contenuR_h2;
	text-align: left;

	strong {
		font-family: $p_montserrat_bold;
	}
}

h3 {
	font-family: $p_montserrat_light;
	color: $black_blue;
	font-size: $pfs_contenuR_h3;
	text-align: left;

	strong {
		font-family: $p_montserrat_bold;
	}
}

h4 {
	font-family: $p_montserrat_light;
	color: $black_blue;
	font-size: $pfs_contenuR_h4;
	text-align: left;

	strong {
		font-family: $p_montserrat_reg;
	}
}

h5 {
	font-family: $p_montserrat_light;
	color: $grey;
	font-size: $pfs_contenuR_h5;
	text-align: left;

	strong {
		font-family: $p_montserrat_reg;
	}
}

p {
	font-family: $p_montserrat_light;
	color: $black_grey;
	font-size: $pfs_contenuR_p;
	text-align: left;

	strong {
		font-family: $p_montserrat_reg;
	}
}

pre {
	font-family: $p_montserrat_light;
	color: $grey;
	font-size: $pfs_contenuR_p;
	text-align: left;

	strong {
		font-family: $p_montserrat_reg;
	}
}

hr {
	background-image: url(../images/points_couleur.png);
	background-repeat: no-repeat;
	background-position: center center;
	height: 9px;
	border-top: none;
	margin: 50px 0px;
}

table {
	border-bottom: 5px solid $smooth2_grey;
	border-top: 5px solid $smooth2_grey;
	border-left: 1px solid $smooth2_grey;
	border-right: 1px solid $smooth2_grey;
	font-family: $p_montserrat_light;
	color: $grey;

	td {
		border: 1px solid $smooth2_grey;
		padding: 5px 10px;
	}

	th {
		border: 1px solid $smooth2_grey;
		padding: 5px 10px;
	}

	caption {
		font-family: $p_montserrat_reg;
		text-transform: uppercase;
	}
}

blockquote {
	padding: 10px 20px;
	margin: 30px 13px 30px;
	font-size: 17.5px;
	border-left: 5px solid $black_blue;
}

ul,
ol {
	font-family: $p_montserrat_light;
	color: $black_grey;
	padding-left: 25px;

	li {
		margin: 4px 0px;
	}
}

a {
	font-family: $p_montserrat_light;
	color: $orange;
	text-decoration: underline;
	@include transition(all 0.2s ease-in-out);

	&:hover,
	&:active,
	&:focus,
	&:active:visited,
	&:active:focus,
	&:active:hover {
		color: $black_blue;
		font-family: $p_montserrat_reg;
	}
}

strong {
	font-family: $p_montserrat_reg;
}

.contenu-r {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;

	.back-point {
		background-image: url(../images/points_couleur.png);
		background-repeat: no-repeat;
		background-position: left 15px center;
	}

	a {
		text-decoration: none;
		font-family: $p_montserrat_light;
	}

	.btn-primary {
		width: auto;
		margin-top: 15px;
		background-color: $white;
		padding: 8px 10px;
		color: $black_blue;
		font-family: $p_montserrat_light;
		font-size: $pfs_contact_button;
		text-transform: uppercase;
		border: 1px solid $smooth2_grey;
		@include transition(all 0.2s ease-in-out);

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $black_blue;
			color: $white;
			border: 1px solid $black_blue;
		}

		strong {
			font-family: $p_montserrat_bold;
		}
	}
}

.redac {
	border-top: 14px solid $smooth_grey;
	background-color: $white;
	padding: 15px;

	&.orientation {
		border-top: 14px solid $red;
	}

	&.formation {
		border-top: 14px solid $orange;
	}

	&.emploi {
		border-top: 14px solid $yellow;
	}

	&.sante {
		border-top: 14px solid $green;
	}

	&.mobilite {
		border-top: 14px solid $purple;
	}

	&.logement {
		border-top: 14px solid $blue;
	}

	.categorie {
		display: inline-block;
		padding: 1px 40px;
		font-family: $p_montserrat_reg;
		color: $white;

		&.sante {
			background-color: $green;
		}

		&.emploi {
			background-color: $yellow;
		}

		&.formation {
			background-color: $orange;
		}

		&.logement {
			background-color: $blue;
		}

		&.orientation {
			background-color: $red;
		}

		&.mobilite {
			background-color: $purple;
		}

		&.ema {
			background-color: $smooth_grey;
			color: $black_grey;
		}

		&.garantie {
			background-color: $smooth_grey;
			color: $black_grey;
		}
	}

	.addthis_inline_share_toolbox {
		margin-bottom: 50px;
	}

	img {
		display: block;
		max-width: 100%;
		margin: 15px 0;
	}
}

.liste {
	border-top: 14px solid $white;
	background-color: $white;
	padding: 0px 20px 40px;

	h2 {
		border-bottom: 5px solid $light_grey;
		padding-bottom: 10px;
	}

	.extrait {
		border-bottom: 1px solid $smooth_grey;
		padding: 0 0 10px;
		@include transition(all 0.2s ease-in-out);

		h4 {
			font-family: $p_montserrat_bold;
		}

		.date {
			color: $blue;
			font-family: $p_montserrat_reg;
		}

		.categorie_sante {
			color: $green;
			font-family: $p_montserrat_reg;
		}

		.categorie_emploi {
			color: $yellow;
			font-family: $p_montserrat_reg;
		}

		.categorie_formation {
			color: $orange;
			font-family: $p_montserrat_reg;
		}

		.categorie_logement {
			color: $blue;
			font-family: $p_montserrat_reg;
		}

		.categorie_orientation {
			color: $red;
			font-family: $p_montserrat_reg;
		}

		.categorie_mobilite {
			color: $purple;
			font-family: $p_montserrat_reg;
		}

		a {
			display: block;
		}

		&:hover {
			background-color: $light_grey;

			h4 {
				color: $grey;
			}

			span {
				padding-left: 10px
			}
		}
	}
}


/* classes utiles */

body {
	font-size: $pfs_texte-courant;
	color: $black_grey;
	line-height: 1.45;
}

.pad-0 {
	padding: 0 0;
}

.pad-left0 {
	padding: 0 0 0 0px;
}

.space-bottom {
	margin-bottom: 50px;
}

.uppercase {
	text-transform: uppercase;
}

.first-letter {
	&::first-letter {
		text-transform: uppercase;
	}
}

.alertconnect {
	margin-top: 10px;
}

.input-group {
	display: flex;
	flex-direction: column;

	.alert {
		order: 1;
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		margin-bottom: 15px;

		& ~ input,
		& ~ textarea,
		& ~ select {
			border: 1px solid #a94442;
			margin-bottom: 0 !important;
		}
	}

	input,
	textarea {
		.form-control {
			margin-bottom: 30px;
		}
	}

	&.radios {
		display: block;
	}
}

//custom select
.select-hidden {
	display: none;
	visibility: hidden;
	padding-right: 10px;
}

.select {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: $grey;
	width: 100%;
	border-radius: 6px !important;
}

.select-styled {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $white;
	border: 1px solid $smooth2_grey;
	padding: 5px 15px;
	@include transition(all 0.2s ease-in);
	border-radius: 6px;

	&:after {
		font: normal normal normal 14px/1 FontAwesome;
		content: "\f078";
		color: $white;
		width: 0;
		height: 0;
		background-color: $black_blue;
		height: 100%;
		width: 32px;
		position: absolute;
		top: 0;
		right: 0;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		padding: 9px;
	}
}

.select-options {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 999;
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: $white;
	max-height: 166px;
	overflow-y: auto;

	li {
		margin: 0;
		padding: 6px 0;
		text-indent: 15px;
		border: 1px solid $light_grey;
		@include transition(all 0.15s ease-in);
		color: $grey;

		&:hover {
			color: $white;
			background: $black_grey;
		}

		&[rel="hide"] {
			display: none;
		}
	}
}


/* Montserrat_light*/

.light {
	font-family: $p_montserrat_light;
}

.no-object {
	padding: 30px;
	text-align: center;
	font-size: $pfs_contenuR_h3;
}

.ombre2 {
	background-image: $bgImg_services2;
	background-position: center center;
	background-repeat: repeat-x;
	width: 100%;
	height: 21px;
	position: absolute;
	bottom: 20px;
	left: 0;
}

.btn-color {
	@include display-flex;
	@include flex-direction;
	@include justify-content-start;
	@include align-items(center);
	width: 100%;
	min-height: 190px;
	padding-top: 48px;
	position: relative;
	margin-bottom: 15px;
	text-decoration: none;
	background-color: $white !important;
	background-image: $bgImg_services_rdv;
	background-repeat: repeat-x;
	background-position: top center;
	@include transition(all 0.2s ease-in-out);

	h1 {
		font-family: $p_montserrat_bold;
		text-align: center;
		position: relative;
		display: inline;
		margin: 0;
		color: $black_blue !important;
		font-size: 26px !important;
		@include transition(all 0.2s ease-in-out);
	}

	h3 {
		color: $red !important;
		font-family: $p_montserrat_light;
		font-size: 24px;
		margin-top: 5px;
		@include transition(all 0.2s ease-in-out);
	}

	.ombre {
		background-image: $bgImg_services;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 21px;
		position: absolute;
		bottom: 20px;
		left: 0;
		max-width: 100%;
		@include transition(all 0.2s ease-in-out);
	}

	.ombre2 {
		background-image: $bgImg_services;
		background-position: center center;
		background-repeat: repeat-x;
		width: 100%;
		height: 21px;
		position: absolute;
		bottom: 20px;
		left: 0;
		max-width: 100%;
		opacity: 1;
		@include transition(all 0.2s ease-in-out);
	}

	.triangle {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		text-align: center;

		img {
			width: 49px;
			@include transition(all 0.2s ease-in-out);
		}
	}

	&:hover,
	&:active,
	&:focus,
	&:active:visited,
	&:active:focus,
	&:active:hover {
		text-decoration: none;
		background-color: $grey !important;

		h1 {
			font-size: 26px !important;
			color: $smooth2_grey !important;
		}

		h3 {
			color: $smooth2_grey !important;
		}

		.ombre,
		.ombre2 {
			bottom: -20px;
			opacity: 0;
		}

		.triangle {
			img {
				width: 107px;
			}
		}
	}
}


//<a href="#" class="rdv btn-color">
//	<h1 class="uppercase">
//		Prends<br>rendez-vous
//	</h1>
//	<h3>avec un conseiller !</h3>
//	<div class="ombre2"></div>
//	<div class="triangle">
//		<img src="images/grey_triangle.png">
//	</div>
//</a>
/* Bouton Fond Blanc formulaire */

.btn-primary-form {
	width: 200px;
	margin-bottom: 10px;
	background-color: $white;
	padding: 8px 0;
	color: $black_blue;
	font-family: $p_montserrat_bold;
	font-size: $pfs_contact_button;
	text-transform: uppercase;
	border: 1px solid $smooth2_grey;
	@include transition(all 0.2s ease-in-out);

	&:hover {
		background-color: $orange;
		color: $white;
		border: 1px solid $smooth2_grey;
	}

	&.non-dispo {
		background-color: $smooth2_grey;
		border: 1px solid $smooth_grey;
		color: $smooth_grey;
	}
}

.btn-primary-form-valid {
	width: auto;
	margin-top: 0px;
	margin-bottom: 15px;
	background-color: $green;
	padding: 8px 60px;
	color: $white;
	font-family: $p_montserrat_bold;
	font-size: $pfs_contact_button;
	text-transform: uppercase;
	border: 1px solid $smooth2_grey;
	@include transition(all 0.2s ease-in-out);

	&:hover {
		background-color: $orange;
		color: $white;
		border: 1px solid $smooth2_grey;
	}
}

.selected {
	background-color: $orange;
	color: $white;
	border: 1px solid $smooth2_grey;
}


/* Bouton Fond Orange */

.btn-submit {
	width: auto;
	margin-top: 15px;
	background-color: $green;
	padding: 8px 60px;
	color: $white;
	font-family: $p_montserrat_bold;
	font-size: $pfs_contact_button;
	text-transform: uppercase;
	border: 1px solid $smooth2_grey;
	@include transition(all 0.2s ease-in-out);

	&:hover,
	&:active,
	&:focus,
	&:active:visited,
	&:active:focus,
	&:active:hover {
		background-color: $black_blue;
		color: $white;
		border: 1px solid $black_blue;
	}
}

.btn-desinscrire {
	background-color: $red !important;
	color: $white !important;
}

//Back to top btn
[id="back-to-top"] {
	cursor: pointer;
	position: fixed;
	bottom: 120px;
	right: 0;
	display: none;
	padding: 20px 25px !important;
	background-color: $black_blue !important;
	color: $white !important;
	font-size: 25px !important;
	border-radius: 0;
	z-index: 5000000000000000;
}

@media#{$media_0_767} {
	[id="back-to-top"] {
		cursor: pointer;
		position: fixed;
		bottom: 50px;
		right: 0;
		padding: 20px 25px !important;
		background-color: $black_blue !important;
		color: $white !important;
		font-size: 25px !important;
		border-radius: 0;
		z-index: 5000000000000000;
	}
}


/* Input formulaire*/

input,
textarea {
	background-color: $light_grey;
	color: $black_blue;
	border: 1px solid $smooth2_grey;
	border-radius: 6px !important;
	font-family: $p_montserrat_light;
	font-size: $pfs_contact_label;
	max-width: 100%;

	&:focus {
		box-shadow: none;

		&::placeholder {
			color: $black_blue;
		}
	}

	&::placeholder {
		color: $grey;
		font-family: $p_montserrat_light;
		font-size: $pfs_contact_label;
	}
}


/* Fond Gris Formulaire */

.block {
	background-color: $smooth_grey;
	border: 1px solid $smooth2_grey;
	padding: 50px 50px;
	border-radius: 6px;
	position: relative;
	margin-bottom: 160px;

	h4 {
		margin: 0 0;
	}

	.etapes {
		position: relative;
		border-bottom: #FFFFFF 1px solid;
		padding-bottom: 30px;
		padding-top: 30px;

		&:not(.date) {
			display: none;
		}

		h4 {
			margin: 0px 0 20px;
		}

		p {
			font-family: $p_montserrat_bold;
		}

		.light {
			font-family: $p_montserrat_light;
			color: $black_grey;
		}

		.flech-form {
			position: absolute;
			top: 25px;
			left: -40px;
		}
	}

	.choix-conseil {
		margin: 50px 0 20px 0;
		padding-top: 40px;
		border-top: 1px solid $smooth2_grey;
	}
}

@media#{$media_0_767} {
	.block {
		margin-bottom: 80px;
	}
}

.choix {
	position: relative;
	margin-bottom: 160px;
	margin-top: 50px;

	.btn-color {
		background-color: $light_grey !important;
		border: solid 1px $smooth2_grey;
		@include transition(all 0.2s ease-in-out);

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $grey !important;

			p {
				color: $smooth2_grey;
			}
		}
	}

	.ombre-block {
		position: absolute;
		bottom: -90px;
	}

	.btn-color {
		background-color: $light_grey !important;
		border: solid 1px $smooth2_grey;
		@include transition(all 0.2s ease-in-out);

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $grey !important;
		}
	}

	.ombre-block {
		position: absolute;
		bottom: -90px;
	}

	.infos {
		margin-bottom: 160px;
	}
}

label {
	margin-right: 30px;
}


/* Titre avec Boutton*/

.titre-page {
	margin-bottom: 40px;
	margin-top: 60px;

	.texte {
		h1 {
			position: relative;
			font-family: $p_montserrat_light;
			color: $c_contact_h2;
			font-size: $pfs_contact_h1;
			padding-bottom: 0px;
			margin-bottom: 0px;
			margin-top: 10px;
			z-index: 20;

			strong {
				font-family: $p_montserrat_bold;
			}
		}

		span {
			position: absolute;
			left: -120px
		}

		.icon-playerpicto_typo {
			left: -15px;
			top: 20px;
			font-size: $pfs_contenuR_h2;
		}

		.categorie_sante {
			color: $green;
		}

		.categorie_emploi {
			color: $yellow;
		}

		.categorie_formation {
			color: $orange;
		}

		.categorie_logement {
			color: $blue;
		}

		.categorie_orientation {
			color: $red;
		}

		.categorie_mobilite {
			color: $purple;
		}
	}

	.back-point {
		background-image: url(../images/points_couleur_repeat.png);
		background-repeat: repeat-x;
		background-position: right center;
	}

	.btn-primary {
		width: auto;
		margin-top: 15px;
		background-color: $white;
		padding: 8px 10px;
		color: $black_blue;
		font-family: $p_montserrat_light;
		font-size: $pfs_contact_button;
		text-transform: uppercase;
		border: 1px solid $smooth2_grey;
		text-decoration: none;
		@include transition(all 0.2s ease-in-out);

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $black_blue;
			color: $white;
			border: 1px solid $black_blue;
		}

		strong {
			font-family: $p_montserrat_bold;
		}
	}
}

//
//<div class="titre-page">
//           <div class="col-md-3 col-md-offset-1 col-sm-4 col-sm-offset-0 col-xs-6">
//              <div class="texte">
//                  <h1><span><img src="images/carre_coul_titre.png" width="136" height="67" alt=""/></span><strong>Contact </strong></h1>
//              </div>
//           </div>
//           <div class="col-md-6 col-sm-5 hidden-xs">
//               <div class="back-point" style="height: 69px"></div>
//           </div>
//           <div class="col-md-2 col-sm-3 col-xs-6 text-right ">
//               <a href="index.html" class="btn btn-primary">Retour <strong>Accueil</strong></a>
//           </div>
//              <div class="clearfix"></div>
//          </div>
@media #{$media_0_991} {
	.titre-page {
		margin-bottom: 50px;

		.texte {
			h1 {
				margin-top: 15px;
				font-size: $pfs_contenuR_h1resp;
				margin-left: 65px;
			}

			span {
				left: -120px
			}
		}
	}
}

@media #{$media_0_767} {
	.titre-page {
		margin-bottom: 50px;

		.texte {
			h1 {
				margin-left: 70px;
			}

			span {
				left: -110px
			}
		}
	}
}

@media #{$media_0_500} {
	.titre-page {
		margin-bottom: 50px;

		.texte {
			h1 {
				margin-left: 73px;
				font-size: 27px;
			}

			span {
				left: -98px;

				img {
					width: 83%;
					height: auto;
				}
			}
		}

		.btn-primary {
			display: none;
		}
	}
}

.titre-service {
	margin-bottom: 30px;
	margin-top: 60px;

	.texte {
		h1 {
			position: relative;
			font-family: $p_montserrat_light;
			color: $c_contact_h2;
			font-size: $pfs_contact_h1;
			padding-left: 10px;
			margin-top: 10px;
			z-index: 20;

			strong {
				font-family: $p_montserrat_bold;
			}
		}

		span {
			position: absolute;
			left: -120px
		}

		.icon-playerpicto_typo {
			left: -15px;
			top: 20px;
			font-size: $pfs_contenuR_h2;
		}

		.categorie_sante {
			color: $green;
		}

		.categorie_emploi {
			color: $yellow;
		}

		.categorie_formation {
			color: $orange;
		}

		.categorie_logement {
			color: $blue;
		}

		.categorie_orientation {
			color: $red;
		}

		.categorie_mobilite {
			color: $purple;
		}
	}

	.back-point {
		background-image: url(../images/points_couleur_repeat.png);
		background-repeat: repeat-x;
		background-position: right center;
	}

	.btn-primary {
		width: auto;
		margin-top: 15px;
		background-color: $white;
		padding: 8px 10px;
		color: $black_blue;
		font-family: $p_montserrat_light;
		font-size: $pfs_contact_button;
		text-transform: uppercase;
		border: 1px solid $smooth2_grey;
		text-decoration: none;
		@include transition(all 0.2s ease-in-out);

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $black_blue;
			color: $white;
			border: 1px solid $black_blue;
		}

		strong {
			font-family: $p_montserrat_bold;
		}
	}
}

@media #{$media_0_991} {
	.titre-service {
		margin-bottom: 50px;

		.texte {
			h1 {
				margin-top: 15px;
				font-size: $pfs_contenuR_h1resp;
				padding-left: 5px;
			}

			.icon-playerpicto_typo {
				left: -15px;
				top: 20px;
				font-size: $pfs_contenuR_h3;
			}
		}
	}
}

@media #{$media_0_767} {
	.titre-service {
		margin-bottom: 30px;

		.texte {
			h1 {
				margin-top: 15px;
				font-size: $pfs_contenuR_h1resp;
				padding-left: 5px;
			}

			.icon-playerpicto_typo {
				left: -15px;
				top: 20px;
				font-size: $pfs_contenuR_h3;
			}
		}
	}
}

.back-point {
	background-image: url(../images/points_couleur_repeat.png);
	background-repeat: repeat-x;
	background-position: center center;
}

.btn-primary {
	width: auto;
	margin-top: 15px;
	background-color: $white;
	padding: 8px 10px;
	color: $black_blue;
	font-family: $p_montserrat_bold;
	font-size: $pfs_contact_button;
	text-transform: uppercase;
	border: 1px solid $smooth2_grey;
	@include transition(all 0.2s ease-in-out);
	text-decoration: none;

	&:hover,
	&:active,
	&:focus,
	&:active:visited,
	&:active:focus,
	&:active:hover {
		background-color: $black_blue;
		font-family: $p_montserrat_bold;
		color: $white;
		border: 1px solid $black_blue;
	}
}

.ombre-block {
	position: absolute;
	bottom: -100px;
	left: 50%;
	transform: translateX(-50%);
	max-width: 100%;
}

//Séparateur pointillés
.separateur {
	display: block;
	margin: auto;
	background-image: $bgImg_separateur;
	background-position: center center;
	background-repeat: no-repeat;
	height: 10px;
	margin-bottom: 20px;
}


/* Animations */

//slideUp
.slideInUp {
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: slideInUp;
}


/* header top */

.dropdown {
	.header {
		background-color: $bg_header;
		background-image: $bgImg_header;
		background-position: left center;
		background-repeat: no-repeat;

		.container {
			background-color: $bg_header_container;

			.btn-container {
				position: relative;
				background-color: $bg_header_menu_btn;
				background-image: none;
				float: left;
				display: block;
				border-radius: 0;
				margin: 0;
				padding: 0;
				border: 0;

				button {
					position: absolute;
					background-color: $bg_header_menu_btn;
					background-image: none;
					float: left;
					display: block;
					border-radius: 0;
					margin: 0;
					padding: 0;
					border: 0;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 16px;
					cursor: pointer;
					@include transition(all 0.2s ease-in-out);

					&:hover,
					&:active,
					&:focus,
					&:active:focus,
					&:active:hover {
						& ~ .btn-menu {
							background-color: $bg_header_menu_btn_picto_hover;

							.icon-bar {
								background-color: $bg_header_menu_btn_barre_hover;
							}
						}
					}
				}

				.btn-menu {
					height: 35px;
					margin-right: 15px;
					padding: 10px 10px;
					background-image: none;
					float: left;
					display: block;
					border-radius: 0;
					margin: 0;
					background-color: $bg_header_menu_btn_picto;

					.icon-bar {
						background-color: $bg_header_menu_btn_barre;
						display: block;
						width: 22px;
						height: 2px;
						border-radius: 1px;

						& + .icon-bar {
							margin-top: 4px;
						}
					}
				}

				.txt-menu {
					float: left;
					display: block;
					border-radius: 0;
					margin: 0;
					padding: 7px 10px;

					p {
						color: $c_header_menu_btn_txt;
						margin-bottom: 0;
					}
				}
			}

			.head-rdv {
				height: 35px;
				background-color: $bg_header_menu_rdv;
				float: left;
				padding: 6px 15px;
				margin-left: 20px;
				text-decoration: none;
				@include transition(all 0.2s ease-in-out);

				i {
					color: $c_header_menu_rdv_i;
					display: inline-block;
					vertical-align: middle;
					margin-right: 3px;
				}

				p {
					color: $c_header_menu_rdv_p;
					margin: 0;
					text-transform: uppercase;
					font-family: $p_montserrat_reg;
					display: inline-block;
					vertical-align: middle;
				}

				&:hover,
				&:active,
				&:focus,
				&:active:focus,
				&:active:hover {
					text-decoration: none;
					background-color: $bg_header_menu_rdv_hover;

					p {
						color: $c_header_menu_rdv_p_hover;
					}
				}
			}

			.btns {
				float: right;

				a {
					float: left;
					display: block;
					text-decoration: none;
					@include transition(all 0.2s ease-in-out);

					&.head-contact {
						padding: 6px 15px;
						text-decoration: none;
						height: 35px;
						@include transition(all 0.2s ease-in-out);

						i {
							display: inline-block;
							vertical-align: middle;
							margin-right: 3px;
							color: $c_header_menu_contact_i;
						}

						p {
							color: $c_header_menu_btns;
							font-family: $p_montserrat_light;
							font-size: $pfs_header_menu_btns;
							display: inline-block;
							vertical-align: middle;
							margin-right: 3px;
							color: $white;
						}

						&:hover,
						&:active,
						&:focus,
						&:active:focus,
						&:active:hover {
							text-decoration: none;
							background-color: $bg_header_menu_contact_hover;

							i {
								color: $c_header_menu_contact_hover;
							}

							p {
								color: $c_header_menu_contact_i_hover;
							}
						}
					}

					&.head-connexion,
					&.head-deconnexion {
						height: 35px;
						padding: 7px 15px;
						background-color: $bg_header_menu_login;
						text-decoration: none;
						@include transition(all 0.2s ease-in-out);

						p {
							color: $c_header_menu_btns;
						}

						&:hover,
						&:active,
						&:focus,
						&:active:focus,
						&:active:hover {
							text-decoration: none;
							background-color: $bg_header_menu_login_hover;

							i {
								color: $c_header_menu_login_hover_i;
							}

							p {
								color: $c_header_menu_login_hover_p;
							}
						}
					}

					p {
						margin: 0;
					}

					&:hover,
					&:active,
					&:focus,
					&:active:focus,
					&:active:hover {
						color: $c_header_menu_btns_hover;
					}
				}

				p.hello {
					margin: 0;
					color: $white;
					padding: 7px 15px;
					float: left;
					display: block;

					span {
						color: $orange;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	ul.dropdown-content {
		position: static;
		padding-left: 0;
		list-style: none;
		background-color: $bg_header_menu_ul;
		box-shadow: none;
		display: none;
		min-width: 160px;
		z-index: 1;
		margin: 0;
		border-bottom: 1px solid $smooth_grey;
		@include transition(all 0.2s ease-in-out);

		.container {
			> li {
				float: left;
				margin: 0;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:focus,
				&:active:hover,
				&.open {
					> a {
						background-color: $bg_header_menu_ul_li;
					}
				}

				> a {
					padding: 40px 20px;
					font-family: $p_montserrat_bold;
					text-transform: lowercase;
					color: $c_header_menu_ul;
					font-size: $pfs_header_menu_ul;
					text-decoration: none;
					display: block;

					&.orientation {
						color: $c_header_menu_ul_li_orientation;
					}

					&.formation {
						color: $c_header_menu_ul_li_formation;
					}

					&.emploi {
						color: $c_header_menu_ul_li_emploi;
					}

					&.sante {
						color: $c_header_menu_ul_li_sante;
					}

					&.mobilite {
						color: $c_header_menu_ul_li_mobilite;
					}

					&.logement {
						color: $c_header_menu_ul_li_logement;
					}

					&.ema {
						text-transform: uppercase;
					}

					&.garantie {
						text-transform: capitalize;
					}
				}

				&.dropdown {
					position: relative;
					display: block;
					@include transition(all 0.2s ease-in-out);

					ul.dropdown-menu {
						position: absolute;
						top: 100%;
						left: 0;
						margin: 0;
						border-radius: 0;
						border: none;
						padding: 0;
						box-shadow: none;

						li {
							a {
								font-family: $p_montserrat_reg;
								font-size: $pfs_header_menu_ul;
								color: $c_header_menu_ul;
								padding: 13px;
								padding-right: 30px;
								text-decoration: none;
								display: block;
								@include transition(all 0.2s ease-in-out);

								&:hover,
								&:active,
								&:focus,
								&:active:focus,
								&:active:hover,
								&.open {
									color: $c_header_menu_ul_li_ul_il_a_hover;
									background-color: $bg_header_menu_ul_li_ul_il_a_hover;
								}
							}
						}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:focus,
					&:active:hover,
						{
						ul.dropdown-menu {
							display: block;
						}
					}
				}
			}
		}
	}

	.show {
		display: block;
	}

	//LOGOS HEAD
	section.head.accueil {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background-color: $transparent;

		.container {
			min-height: 160px;
			@include display-flex;
			@include flex-direction-row;
			@include flex-direction-between;
			@include align-items(center);
			position: relative;

			&::after,
			&::before {
				display: none;
			}

			img {
				float: right;
				max-width: 100%;

				&:first-child {
					float: left;
				}
			}

			.alert {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				transform: translateY(-50%);
			}
		}
	}
}


@media #{$media_0_767} {
	.dropdown {
		.header {
			.container {
				.btn-container {
					button {

						&:hover,
						&:active,
						&:focus,
						&:active:focus,
						&:active:hover {
							& ~ .btn-menu {
								.icon-bar {}
							}
						}
					}

					.btn-menu {
						.icon-bar {
							& + .icon-bar {}
						}
					}

					.txt-menu {
						p {}
					}
				}

				.head-rdv {
					display: none;

					i {}

					p {}

					&:hover,
					&:active,
					&:focus,
					&:active:focus,
					&:active:hover {
						p {}
					}
				}

				.btns {
					a {
						&.head-contact {
							i {}

							p {}

							&:hover,
							&:active,
							&:focus,
							&:active:focus,
							&:active:hover {
								i {}

								p {}
							}
						}

						&.head-connexion,
						&.head-deconnexion {

							&:hover,
							&:active,
							&:focus,
							&:active:focus,
							&:active:hover {
								i {}

								p {}
							}
						}

						p {}

						&:hover,
						&:active,
						&:focus,
						&:active:focus,
						&:active:hover {}
					}
				}
			}
		}

		ul.dropdown-content {
			.container {
				> li {
					float: none;
					display: block;

					&:hover,
					&:active,
					&:focus,
					&:active:focus,
					&:active:hover,
					&.open {
						> a {}
					}

					> a {
						padding: 20px 40px;

						&.orientation {}

						&.formation {}

						&.emploi {}

						&.sante {}

						&.mobilite {}

						&.logement {}
					}

					&.dropdown {
						ul.dropdown-menu {
							padding-left: 50px;
							width: 100%;
							position: static;

							li {
								a {

									&:hover,
									&:active,
									&:focus,
									&:active:focus,
									&:active:hover,
									&.open {}
								}
							}
						}

						&:hover,
						&:active,
						&:focus,
						&:active:focus,
						&:active:hover,
							{
							ul.dropdown-menu {}
						}
					}
				}
			}
		}
	}
}

section.head {
	top: 100%;
	left: 0;
	width: 100%;
	background-color: $head_grey;

	.container {
		min-height: 160px;
		@include display-flex;
		@include flex-direction-row;
		@include flex-direction-between;
		@include align-items(center);
		height: 1px;

		&::after,
		&::before {
			display: none;
		}

		img {
			float: right;
			max-width: 100%;

			&:first-child {
				float: left;
			}
		}
	}
}

@media#{$media_0_767} {
	section.head {
		.container {

			&::after,
			&::before {}

			img {
				margin: auto;

				&:nth-child(2) {
					display: none;
				}
			}
		}
	}
}

section.fil-ariane {
	background-color: $grey_ariane;
	padding-top: 5px;

	a {
		display: inline-block;
		text-decoration: none;
		color: $white;

		& + a {
			font: normal normal normal 14px/1 FontAwesome;
			@include transition(all 0.2s ease-in-out);

			&:before {
				//                content: "\e258";
				content: "";
				margin-right: 5px;
				margin-left: 5px;
				font-size: $pfs_contact_mention;
			}

			&:hover,
			&:active,
			&:focus,
			&:active:focus,
			&:active:hover,
				{
				font: normal normal normal 14px/1 FontAwesome;

				&:before {
					//                content: "\e258";
					content: "";
					margin-right: 5px;
					margin-left: 5px;
					font-size: $pfs_contact_mention;
				}
			}
		}

		p {
			display: inline-block;
			color: $grey;
			font-size: $pfs_contact_mention;
		}

		&:hover,
		&:active,
		&:focus,
		&:active:focus,
		&:active:hover,
			{
			p {
				color: $black_grey;
			}
		}
	}
}


/* home page */

//SERVICES
section.services {
	@include degrade_accueil_services;

	.container {
		padding-top: 160px;

		a {
			@include display-flex;
			@include flex-direction;
			@include justify-content-start;
			@include align-items(center);
			width: 100%;
			min-height: 190px;
			background-color: $red;
			padding-top: 48px;
			position: relative;
			margin-bottom: 15px;
			text-decoration: none;
			@include transition(all 0.2s ease-in-out);

			h1 {
				font-family: $p_montserrat_bold;
				font-size: 28px;
				color: $white;
				text-align: center;
				position: relative;
				display: inline;
				margin: 0;
				@include transition(all 0.2s ease-in-out);

				span {
					position: absolute;
					bottom: -3px;
					left: -13px;
					font-size: 17px;
					color: #dd4075;
					@include transition(all 0.2s ease-in-out);
				}
			}

			.ombre {
				background-image: $bgImg_services;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				width: 100%;
				height: 21px;
				position: absolute;
				bottom: 20px;
				left: 0;
				opacity: 1;
				@include transition(all 0.2s ease-in-out);
			}

			.ombre2 {
				background-image: $bgImg_services2;
				background-position: center center;
				background-repeat: repeat-x;
				width: 100%;
				height: 21px;
				position: absolute;
				bottom: 20px;
				left: 0;
				opacity: 1;
				@include transition(all 0.2s ease-in-out);
			}

			.triangle {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				text-align: center;

				img {
					width: 49px;
					@include transition(all 0.2s ease-in-out);
				}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				text-decoration: none;

				h1 {
					font-size: 41px;

					span {
						font-size: 26px;
					}
				}

				.ombre,
				.ombre2 {
					bottom: -20px;
					opacity: 0;
				}

				.triangle {
					img {
						width: 107px;
					}
				}
			}

			&.orientation {
				background-color: $red;

				h1 {
					span {
						color: #dd4075;
					}
				}
			}

			&.formation {
				background-color: $orange;

				h1 {
					span {
						color: #fda957;
					}
				}
			}

			&.emploi {
				background-color: $yellow;

				h1 {
					span {
						color: #fecb55;
					}
				}
			}

			&.sante {
				background-color: $green;

				h1 {
					span {
						color: #a4d155;
					}
				}
			}

			&.mobilite {
				background-color: $purple;

				h1 {
					span {
						color: #b14a81;
					}
				}
			}

			&.garantie {
				background-color: $smooth_grey;
				padding-top: 25px;
				@include transition(all 0.2s ease-in-out);

				h1 {
					color: $black_blue;
					font-size: 26px;
					margin-bottom: 15px;
					@include transition(all 0.2s ease-in-out);
				}

				.elements {
					width: 100%;
					@include display-flex;
					@include flex-direction-row;
					@include justify-content(center);
					@include align-items(center);
					padding: 0 30px;

					img {
						margin-right: 15px;
					}

					p {
						margin-left: 15px;
						color: $black_grey;
						font-family: $p_montserrat_bold;
						font-size: 14px;
						@include transition(all 0.2s ease-in-out);
						margin-bottom: 30px;

						span {
							font-family: $p_montserrat_light;
						}
					}
				}

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					text-decoration: none;
					background-color: $grey !important;

					h1 {
						font-size: 26px !important;
						color: $smooth2_grey !important;
					}

					.elements {
						img {}

						p {
							color: $smooth2_grey !important;

							span {}
						}
					}

					.ombre,
					.ombre2 {
						bottom: -20px;
						opacity: 0;
					}

					.triangle {
						img {
							width: 107px;
						}
					}
				}
			}

			&.logement {
				background-color: $blue;

				h1 {
					span {
						color: #4facdd;
					}
				}
			}

			&.employeur {
				background-color: $smooth_grey;
				margin-bottom: 35px;
				padding-top: 25px;
				@include transition(all 0.2s ease-in-out);

				h1 {
					color: $black_blue;
					font-size: 26px;

					span {
						font-family: $p_montserrat_light;
						position: static;
						bottom: 0;
						left: 0;
						font-size: 26px;
						color: $black_blue;
					}
				}

				p {
					font-family: $p_montserrat_bold;
					font-size: 20px;
					color: $black_blue;
					text-align: center;
					margin-top: 8px;

					span {
						font-family: $p_montserrat_light;
					}
				}

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					text-decoration: none;
					background-color: $grey !important;

					h1 {
						font-size: 26px !important;
						color: $smooth2_grey !important;

						span {
							color: $smooth2_grey !important;
						}
					}

					p {
						color: $smooth2_grey !important;
					}

					.ombre,
					.ombre2 {
						bottom: -20px;
						opacity: 0;
					}

					.triangle {
						img {
							width: 107px;
						}
					}
				}
			}
		}

		.block-logo-jeunes {
			position: relative;
			margin-bottom: 55px;

			img {
				margin: auto;
				display: block;
				margin-bottom: 15px;

				&.fleche-services {
					opacity: 0;
				}
			}

			.ombre {
				position: absolute;
				bottom: -43px;
				left: 50%;
				transform: translateX(-50%);
				max-width: 100%;
			}
		}

		.ema {
			background-color: $black_grey;
			background-image: $bgImg_services_ema;
			background-position: top left;
			background-repeat: repeat-y;
			color: $white;
			@include display-flex;
			@include flex-direction-row;
			@include justify-content-around;
			@include align-items(center);
			flex-wrap: wrap;
			min-height: 144px;
			margin-bottom: 30px;
			padding-top: 7px;
			@include transition(all 0.2s ease-in-out);

			.titre {
				width: 210px;

				h2 {
					font-family: $p_montserrat_bold;
					font-size: 28px;
					text-align: center;
					margin: 0;
					color: $white;
				}

				h3 {
					text-align: center;
					color: $white;
					font-family: $p_montserrat_light;
					font-size: 20px;
					line-height: 22px;
				}
			}

			.txt {
				p {
					color: $white;

					&.point-info {
						font-family: $p_montserrat_light;
						font-size: 16px;
						line-height: 21px;

						span {
							font-family: $p_montserrat_bold;
						}
					}

					&.ouvert {
						font-family: $p_montserrat_bold;
						font-size: 20px;

						span {
							color: $yellow;
						}
					}
				}
			}

			.logo {
				img {}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				background-color: $smooth2_grey;

				.titre {
					h2 {
						color: $black_blue;
					}

					h3 {
						color: $black_blue;
					}
				}

				.txt {
					p {
						&.point-info {
							span {}
						}

						&.ouvert {
							span {}
						}
					}
				}

				.logo {
					img {}
				}
			}
		}
	}
}

@media #{$media_0_767} {
	section.services {
		.container {
			a {
				&.garantie {
					h1 {
						font-size: 22px;
					}

					.elements {
						@include flex-direction;

						img {
							margin: 0;
							margin-bottom: 15px;
						}

						p {
							margin: 0;
							margin-bottom: 70px;
						}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						h1 {}

						.elements {
							img {}

							p {

								span {}
							}
						}

						.ombre,
						.ombre2 {}

						.triangle {
							img {}
						}
					}
				}

				&.employeur {
					h1 {

						span {}
					}

					p {
						font-size: 17px;

						span {}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						h1 {
							span {}
						}

						p {}

						.ombre,
						.ombre2 {}

						.triangle {
							img {}
						}
					}
				}
			}

			.ema {
				padding: 32px 45px;

				.titre {
					h2 {}

					h3 {}
				}

				.txt {
					margin: 21px 0;

					p {
						&.point-info {
							span {}
						}

						&.ouvert {
							span {}
						}
					}
				}

				.logo {
					img {}
				}

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					.titre {
						h2 {}

						h3 {}
					}

					.txt {
						p {
							&.point-info {
								span {}
							}

							&.ouvert {
								span {}
							}
						}
					}

					.logo {
						img {}
					}
				}
			}
		}
	}
}

//SLIDER
.icon-default {
	background-image: $bgImg_slider_nav;
	display: block;
	width: 87px;
	height: 123px;
	opacity: 1;
	background-position: 1px 0;
	z-index: 50;
	top: 45%;
}

.slider {
	margin-bottom: 25px;

	.flexslider {
		border: none;
		margin: 0;
		@include transition(all 0.2s ease-in-out);

		.slides {
			background-color: $black_grey;

			.item {
				width: 100%;
				position: relative;

				.slider-infos {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 49;
					@include display-flex;
					@include flex-direction;
					@include justify-content(center);
					@include align-items(center);

					.slide-infos-cont {
						overflow: hidden;
						padding: 15px;
						color: $white;
						text-align: center;

						h2 {
							text-align: center;
							color: $white;
							font-family: $p_montserrat_bold;
							font-size: 45px;
							text-shadow: 0 4px 1px rgba(0, 0, 0, 0.5);
							text-transform: uppercase;
						}

						> p {
							text-align: center;
							color: $white;
							text-shadow: 0 4px 1px rgba(0, 0, 0, 0.5);
							font-family: $p_montserrat_light;
							font-size: 30px;
							margin: 0;
							margin-bottom: 30px;
						}

						> i {
							font-size: 55px;
							display: inline-block;
							margin-top: -10px;
						}

						.btn {
							background-color: $forty_black;
							border: 1px solid $black;
							padding: 10px 30px;
							text-decoration: none;
							border-radius: 6px;
							@include transition(all 0.2s ease-in-out);
							margin-top: 10px;

							p {
								color: $white;
								font-family: $p_montserrat_bold;
								font-size: 14px;
								display: inline-block;
								margin: 0;
							}

							i {
								display: inline-block;
								margin-left: 5px;
							}

							&:hover,
							&:active,
							&:focus,
							&:active:visited,
							&:active:focus,
							&:active:hover {
								background-color: $orange;
								border: 1px solid $black; //                                color: $dark-grey;
							}
						}
					}
				}

				img {
					object-fit: cover;
					height: 720px;
				}

				.trame {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					.trame-g {
						float: left;
						height: 100%;
						background-image: $bgImg_slider_nav_li_left;
						background-position: left;
						background-repeat: repeat-y;
						max-width: 190px;
						width: 100%;
					}

					.trame-d {
						float: right;
						height: 100%;
						background-image: $bgImg_slider_nav_li_right;
						background-position: right;
						background-repeat: repeat-y;
						max-width: 190px;
						width: 100%;
					}
				}
			}
		}

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			.flex-direction-nav {
				li {
					&.flex-nav-prev {
						.flex-prev {
							left: 80px;
							opacity: 1;
							display: block;
						}
					}

					&.flex-nav-next {
						.flex-next {
							right: 80px;
							opacity: 1;
							display: block;
						}
					}
				}
			}
		}

		.flex-direction-nav {
			li {
				&.flex-nav-prev {
					background-image: $bgImg_slider_nav_li_left;

					.flex-prev {
						@extend .icon-default;
						left: 40px;
						@include transition(all 0.2s ease-in-out);

						&:before {
							content: none !important;
						}

						&:hover,
						&:active,
						&:focus,
						&:active:visited,
						&:active:focus,
						&:active:hover {
							opacity: 1;
							background-image: $bgImg_slider_nav_hover;
						}
					}
				}

				&.flex-nav-next {
					background-image: $bgImg_slider_nav_li_right;

					.flex-next {
						@extend .icon-default;
						right: 40px;
						background-position: -90px 0;
						@include transition(all 0.2s ease-in-out);

						&:before {
							content: none !important;
						}

						&:hover,
						&:active,
						&:focus,
						&:active:visited,
						&:active:focus,
						&:active:hover {
							opacity: 1;
							background-image: $bgImg_slider_nav_hover;
						}
					}
				}
			}
		}

		.flex-control-nav {
			bottom: 75px;

			li {
				background-color: $white;
				border: 1px solid $black;
				border-radius: 20px;
				height: 14px;
				width: 14px;
				@include transition(all 0.2s ease-in-out);

				a {
					opacity: 0;
					margin-left: 1px;
					margin-top: 1px;
					width: 10px;
					height: 10px;
					background-color: $grey;

					&.flex-active {
						opacity: 1;
						background-color: $black;
					}
				}

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					a {
						opacity: 1;

						&.flex-active {}
					}
				}
			}
		}
	}
}

@media #{$media_0_1199} {
	.slider {
		.flexslider {
			.slides {
				.item {
					.slider-infos {
						.slide-infos-cont {
							width: 800px;
							margin: auto;

							h2 {}

							> p {}

							> i {}

							.btn {
								p {}

								i {}

								&:hover,
								&:active,
								&:focus,
								&:active:visited,
								&:active:focus,
								&:active:hover {}
							}
						}
					}

					img {
						height: 500px;
					}

					.trame {
						.trame-g {}

						.trame-d {}
					}
				}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				.flex-direction-nav {
					li {
						&.flex-nav-prev {
							.flex-prev {}
						}

						&.flex-nav-next {
							.flex-next {}
						}
					}
				}
			}

			.flex-direction-nav {

				li {
					&.flex-nav-prev {
						.flex-prev {
							&:before {}

							&:hover,
							&:active,
							&:focus,
							&:active:visited,
							&:active:focus,
							&:active:hover {}
						}
					}

					&.flex-nav-next {
						.flex-next {
							&:before {}

							&:hover,
							&:active,
							&:focus,
							&:active:visited,
							&:active:focus,
							&:active:hover {}
						}
					}
				}
			}

			.flex-control-nav {
				bottom: 20px;

				li {
					a {
						&.flex-active {}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						a {
							&.flex-active {}
						}
					}
				}
			}
		}
	}
}

@media #{$media_0_991} {
	.slider {
		.flexslider {
			.slides {
				.item {
					.slider-infos {
						.slide-infos-cont {
							width: 500px;

							h2 {
								font-size: 25px;
							}

							> p {
								font-size: 18px;
							}

							> i {}

							.btn {
								p {}

								i {}

								&:hover,
								&:active,
								&:focus,
								&:active:visited,
								&:active:focus,
								&:active:hover {}
							}
						}
					}

					img {}

					.trame {
						.trame-g {
							background-size: contain;
							width: 15%;
						}

						.trame-d {
							background-size: contain;
							width: 15%;
						}
					}
				}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				.flex-direction-nav {
					li {
						&.flex-nav-prev {
							.flex-prev {
								background-size: 100px;
								background-repeat: no-repeat;
								background-position: center left;
								width: 44px;
							}
						}

						&.flex-nav-next {
							.flex-next {
								background-size: 100px;
								background-repeat: no-repeat;
								background-position: center right;
								width: 44px;
							}
						}
					}
				}
			}

			.flex-direction-nav {

				li {
					&.flex-nav-prev {
						.flex-prev {
							background-size: 100px;
							background-repeat: no-repeat;
							background-position: center left;
							width: 44px;
						}
					}

					&.flex-nav-next {
						.flex-next {
							background-size: 100px;
							background-repeat: no-repeat;
							background-position: center right;
							width: 44px;
						}
					}
				}
			}

			.flex-control-nav {
				li {
					a {
						&.flex-active {}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						a {
							&.flex-active {}
						}
					}
				}
			}
		}
	}
}

@media #{$media_0_767} {
	.slider {
		display: none;

		+ .separateur {
			display: none;
		}

		.flexslider {
			.slides {
				.item {
					.slider-infos {
						.slide-infos-cont {
							h2 {
								font-size: 20px;
								height: 50px;
								text-overflow: ellipsis;
								overflow: hidden;
							}

							> p {
								font-size: 16px;
							}

							> i {
								display: none;
							}

							.btn {
								p {}

								i {}

								&:hover,
								&:active,
								&:focus,
								&:active:visited,
								&:active:focus,
								&:active:hover {}
							}
						}
					}

					img {}

					.trame {
						.trame-g {}

						.trame-d {}
					}
				}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				.flex-direction-nav {
					li {
						&.flex-nav-prev {
							.flex-prev {}
						}

						&.flex-nav-next {
							.flex-next {}
						}
					}
				}
			}

			.flex-direction-nav {
				display: none;

				li {
					&.flex-nav-prev {
						.flex-prev {
							&:before {}

							&:hover,
							&:active,
							&:focus,
							&:active:visited,
							&:active:focus,
							&:active:hover {}
						}
					}

					&.flex-nav-next {
						.flex-next {
							&:before {}

							&:hover,
							&:active,
							&:focus,
							&:active:visited,
							&:active:focus,
							&:active:hover {}
						}
					}
				}
			}

			.flex-control-nav {
				display: none;

				li {
					a {
						&.flex-active {}
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						a {
							&.flex-active {}
						}
					}
				}
			}
		}
	}
}


/* Actualités */

section.actu-titre {
	.container {
		.titre-page {
			margin-bottom: 30px;

			.back-point {
				@include display-flex;
				@include flex-direction;
				@include justify-content(center);
				@include align-items(flex-end);
				background-image: none;
			}

			i {
				font-size: 27px;
				float: right;
				margin-top: 25px;
				margin-right: 15px;
				color: $smooth2_grey;
			}

			.btn-primary {
				color: $black;
				float: right;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					color: $white;
				}
			}
		}
	}
}

section.actu-slider {
	background-color: $smooth_grey;
	margin-bottom: 90px;

	.container {
		background-color: $white;

		.lien-actu {
			border-top: 14px solid $grey;
			border-bottom: 14px solid $grey;
			display: block;
			text-decoration: none;
			padding-bottom: 15px;
			position: relative;
			@include transition(all 0.2s ease-in-out);
			margin-bottom: 30px;

			.img {
				height: 270px;
				overflow: hidden;
				width: 100%;
				background-color: $black;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				position: relative;

				.categorie {
					width: 140px;
					height: 25px;
					position: absolute;
					bottom: 27px;
					left: 0;
					@include display-flex;
					@include flex-direction;
					@include justify-content(center);
					@include align-items(center);

					p {
						text-align: center;
						color: $white;
						font-family: $p_montserrat_light;
						font-size: 14px;
						margin: 0;
					}
				}
			}

			&.orientation {
				border-color: $red;

				.img {
					.categorie {
						background-color: $red;
					}
				}
			}

			&.formation {
				border-color: $orange;

				.img {
					.categorie {
						background-color: $orange;
					}
				}
			}

			&.emploi {
				border-color: $yellow;

				.img {
					.categorie {
						background-color: $yellow;
					}
				}
			}

			&.sante {
				border-color: $green;

				.img {
					.categorie {
						background-color: $green;
					}
				}
			}

			&.mobilite {
				border-color: $purple;

				.img {
					.categorie {
						background-color: $purple;
					}
				}
			}

			&.garantie {
				border-color: $smooth_grey;

				.img {
					.categorie {
						background-color: $smooth_grey;

						p {
							color: $black_grey;
							text-transform: capitalize;
						}
					}
				}
			}

			&.logement {
				border-color: $blue;

				.img {
					.categorie {
						background-color: $blue;
					}
				}
			}

			&.employeur {
				border-color: $smooth_grey;

				.img {
					.categorie {
						background-color: $smooth_grey;
					}
				}
			}

			&.ema {
				border-color: $smooth_grey;

				.img {
					.categorie {
						background-color: $smooth_grey;

						p {
							color: $black_grey;
							text-transform: uppercase;
						}
					}
				}
			}

			h2 {
				margin-top: 7px;
				font-family: $p_montserrat_bold;
				font-size: 24px;
				color: $purple;
				overflow: hidden;
				height: 52px;
			}

			.amorce-actu {
				font-family: $p_montserrat_light;
				font-size: 14px;
				color: $black;
				overflow: hidden;
				height: 62px;
			}

			.date-actu {
				font-family: $p_montserrat_light;
				font-size: 14px;
				color: $blue;
				overflow: hidden;
				height: 18px;
			}

			.filtre {
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				background-color: #0b3551;
				opacity: 0.5;
				height: 100%;
				width: 100%;

				.plus {
					height: 270px;
					width: 100%;
					background-image: $bgImg_actus_home_plus;
					background-position: center center;
					background-repeat: no-repeat;
					opacity: 0.6;
				}
			}

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				.filtre {
					display: block;
				}
			}
		}
	}
}

section.liste-actus {
	background-color: $transparent;
}


/* Offre d'emploi */

section.offre-emploi-titre {
	.container {
		.titre-page {
			margin-bottom: 30px;

			.texte {
				h1 {
					font-family: $p_montserrat_light;

					.color {
						position: static;
						left: 0;
						font-family: $p_montserrat_bold;

						&.yellow {
							color: $yellow;
						}
					}
				}
			}

			.back-point {
				@include display-flex;
				@include flex-direction;
				@include justify-content(center);
				@include align-items(flex-end);
				background-image: none;
			}

			i {
				font-size: 27px;
				float: right;
				margin-top: 25px;
				margin-right: 15px;
				color: $smooth2_grey;
			}

			.btn-primary {
				color: $black;
				float: right;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					color: $white;
				}
			}
		}
	}
}

section.offre-emploi-slider {
	background-color: $light_grey;
	margin-bottom: 90px;
	position: relative;

	.container {
		background-color: $white;

		.flexslider {
			margin: 0;

			.slides {
				.lien-offre {
					border-top: 14px solid $yellow;
					border-left: 1px solid $yellow;
					background-color: $light_grey;
					display: block;
					text-decoration: none;
					position: relative;
					padding: 30px 0 80px;
					@include transition(all .2s);
					margin-bottom: 30px;

					.date-offre {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $orange;
						overflow: hidden;
						height: 18px;
						padding: 0 38px;
						@include transition(all .2s);
					}

					.titre-offre {
						padding: 10px 0;
						background-color: $white;
						@include transition(all .2s);

						h2 {
							margin-top: 7px;
							padding: 0 38px;
							font-family: $p_montserrat_bold;
							font-size: 24px;
							color: $black_blue;
							overflow: hidden;
							height: 52px;
						}
					}

					.lieu-offre {
						color: $black_blue;
						font-family: $p_montserrat_bold;
						font-size: 14px;
						overflow: hidden;
						height: 20px;
						padding: 0 38px;
						margin-top: 10px;
					}

					.amorce-offre {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $black;
						overflow: hidden;
						height: 80px;
						padding: 0 38px;
						margin-bottom: 20px;
					}

					.lien-txt {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $orange;
						padding: 0 38px;
						text-decoration: underline;
						margin: 0;
						@include transition(all .2s);
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						background-color: $blue_grey;

						.date-offre {
							color: $white;
						}

						.titre-offre {
							background-color: $yellow;
						}

						.lien-txt {
							color: $white;
						}
					}
				}
			}
		}
	}

	.custom-navigation {
		position: absolute;
		bottom: -70px;
		left: 50%;
		transform: translateX(-50%);
		@include display-flex;

		a {
			display: block;
			width: 52px;
			background-color: $white;
			height: 45px;
			border-radius: 6px;
			border: 1px solid $grey;
			background-position: center center;
			background-repeat: no-repeat;
			@include transition(all 0.2s ease-in-out);

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				background-color: $orange;
			}

			&.flex-prev {
				background-image: $bgImg_offre_home_nav_left;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					background-image: $bgImg_offre_home_nav_left_hover;
				}
			}

			&.flex-next {
				background-image: $bgImg_offre_home_nav_right;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					background-image: $bgImg_offre_home_nav_right_hover;
				}
			}
		}

		.points {
			display: block;
			width: 150px;
			height: 45px;
			background-image: $bgImg_offre_home_nav_points;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}

	&.formation {
		margin-bottom: 130px;
	}
}

@media #{$media_0_767} {
	section.offre-emploi-slider {
		background-color: $light_grey;
		margin-bottom: 90px;
		position: relative;

		.container {
			.lien-offre {
				border-top: 14px solid $yellow;
				border-left: 1px solid $yellow;
				background-color: $light_grey;
				display: block;
				text-decoration: none;
				position: relative;
				padding: 30px 0 80px;
				@include transition(all .2s);
				margin-bottom: 30px;

				.date-offre {
					font-family: $p_montserrat_light;
					font-size: 14px;
					color: $orange;
					overflow: hidden;
					height: 18px;
					padding: 0 38px;
					@include transition(all .2s);
				}

				.titre-offre {
					padding: 10px 0;
					background-color: $white;
					@include transition(all .2s);

					h2 {
						margin-top: 7px;
						padding: 0 38px;
						font-family: $p_montserrat_bold;
						font-size: 24px;
						color: $black_blue;
						overflow: hidden;
						height: 52px;
					}
				}

				.lieu-offre {
					color: $black_blue;
					font-family: $p_montserrat_bold;
					font-size: 14px;
					overflow: hidden;
					height: 20px;
					padding: 0 38px;
					margin-top: 10px;
				}

				.amorce-offre {
					font-family: $p_montserrat_light;
					font-size: 14px;
					color: $black;
					overflow: hidden;
					height: 80px;
					padding: 0 38px;
					margin-bottom: 20px;
				}

				.lien-txt {
					font-family: $p_montserrat_light;
					font-size: 14px;
					color: $orange;
					padding: 0 38px;
					text-decoration: underline;
					margin: 0;
					@include transition(all .2s);
				}

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					background-color: $blue_grey;

					.date-offre {
						color: $white;
					}

					.titre-offre {
						background-color: $yellow;
					}

					.lien-txt {
						color: $white;
					}
				}
			}
		}
	}
}


/* Offre de formation */

section.offre-formation-titre {
	.container {
		.titre-page {
			margin-bottom: 30px;

			.texte {
				h1 {
					font-family: $p_montserrat_light;

					.color {
						position: static;
						left: 0;
						font-family: $p_montserrat_bold;

						&.yellow {
							color: $orange;
						}
					}
				}
			}

			.back-point {
				@include display-flex;
				@include flex-direction;
				@include justify-content(center);
				@include align-items(flex-end);
				background-image: none;
			}

			i {
				font-size: 27px;
				float: right;
				margin-top: 25px;
				margin-right: 15px;
				color: $smooth2_grey;
			}

			.btn-primary {
				color: $black;
				float: right;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					color: $white;
				}
			}
		}
	}
}

section.offre-formation-slider {
	background-color: $light_grey;
	margin-bottom: 140px;
	position: relative;

	.container {
		background-color: $white;

		.flexslider {
			margin: 0;

			.slides {
				.lien-offre {
					border-top: 14px solid $orange;
					border-left: 1px solid $orange;
					background-color: $light_grey;
					display: block;
					text-decoration: none;
					position: relative;
					padding: 30px 0 80px;
					@include transition(all .2s);

					.date-offre {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $orange;
						overflow: hidden;
						height: 18px;
						padding: 0 38px;
						@include transition(all .2s);
					}

					.titre-offre {
						padding: 10px 0;
						background-color: $white;
						@include transition(all .2s);

						h2 {
							margin-top: 7px;
							padding: 0 38px;
							font-family: $p_montserrat_bold;
							font-size: 24px;
							color: $black_blue;
							overflow: hidden;
							height: 52px;
						}
					}

					.lieu-offre {
						color: $black_blue;
						font-family: $p_montserrat_bold;
						font-size: 14px;
						overflow: hidden;
						height: 20px;
						padding: 0 38px;
						margin-top: 10px;
					}

					.amorce-offre {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $black;
						overflow: hidden;
						height: 80px;
						padding: 0 38px;
						margin-bottom: 20px;
					}

					.lien-txt {
						font-family: $p_montserrat_light;
						font-size: 14px;
						color: $orange;
						padding: 0 38px;
						text-decoration: underline;
						margin: 0;
						@include transition(all .2s);
					}

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						background-color: $blue_grey;

						.date-offre {
							color: $white;
						}

						.titre-offre {
							background-color: $yellow;
						}

						.lien-txt {
							color: $white;
						}
					}
				}
			}
		}
	}

	.custom-navigation {
		position: absolute;
		bottom: -70px;
		left: 50%;
		transform: translateX(-50%);

		a {
			display: inline-block;
			width: 52px;
			background-color: $white;
			height: 45px;
			border-radius: 6px;
			border: 1px solid $grey;
			background-position: center center;
			background-repeat: no-repeat;
			@include transition(all 0.2s ease-in-out);

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {
				background-color: $orange;
			}

			&.flex-prev {
				background-image: $bgImg_offre_home_nav_left;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					background-image: $bgImg_offre_home_nav_left_hover;
				}
			}

			&.flex-next {
				background-image: $bgImg_offre_home_nav_right;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					background-image: $bgImg_offre_home_nav_right_hover;
				}
			}
		}

		.points {
			display: inline-block;
			width: 150px;
			height: 45px;
			background-image: $bgImg_offre_home_nav_points;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}

	&.formation {
		margin-bottom: 130px;
	}
}

section.detail-offre {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;
	margin-bottom: 80px;

	.separateur {
		margin: 30px 0px 30px 0px;
	}

	.liens {
		padding: 8px 0px 30px;

		a {
			text-decoration: none;
			margin-right: 25px;
			padding: 8px 20px;
		}

		.btn-primary {
			margin-top: 15px
		}

		.btn-submit {
			margin-top: 15px
		}

		.form-control {
			margin-top: 15px;
			height: 38px;
		}
	}

	.content {
		height: auto;
		background-color: $white;
		border: 1px solid $smooth_grey;
		position: relative;

		.pre-requis {
			h2 {
				font-size: $pfs_contenuR_h2;
			}

			h4 {}

			hr {
				height: 10px;
				background-color: $smooth2_grey;
				background-image: none;
				margin: 0px 0px;
			}

			p {
				margin: 15px 0 15px 0;

				strong {
					color: $black_blue;
				}
			}

			padding: 20px 20px 20px 20px;
		}

		.details {
			padding: 20px 20px 20px 20px;
			min-height: 266px;

			h1 {
				font-family: $p_montserrat_bold;
				font-size: $pfs_contenuR_h3;
				color: $orange;
			}
		}

		.coordonnees-conseiller {
			padding: 20px;

			.bg {
				background-color: $green;
				padding: 20px;

				p {
					color: $white;

					&.txt-amorce {
						font-family: $p_montserrat_bold;
						font-size: 16px;
					}
				}
			}
		}
	}
}

@media #{$media_0_1199} {
	section.detail-offre {
		.liens {
			padding: 8px 0px 30px;

			a {
				text-decoration: none;
				margin-right: 5px;
				padding: 8px 10px;
			}

			.btn-primary {
				margin-top: 15px
			}

			.btn-submit {
				margin-top: 15px
			}

			.form-control {
				margin-top: 15px;
				height: 38px;
			}
		}

		.content {
			height: auto;
			background-color: $white;
			border: 1px solid $smooth_grey;

			.pre-requis {
				h2 {
					font-size: $pfs_contenuR_h2;
				}

				h4 {}

				hr {
					height: 10px;
					background-color: $smooth2_grey;
					background-image: none;
					margin: 0px 0px;
				}

				p {
					margin: 15px 0 15px 0;

					strong {
						color: $black_blue;
					}
				}

				padding: 20px 20px 20px 20px;
			}

			.details {
				padding: 20px 20px 20px 20px;

				h1 {
					font-family: $p_montserrat_bold;
					font-size: $pfs_contenuR_h3;
					color: $orange;
				}
			}
		}
	}
}

section.liste-offre {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;

	.demarches {
		position: absolute;
		right: 0;
		top: 0;
		border: 0;
		border-radius: 0;
		color: $white;
		font-family: "montserratlight";
		height: 35px;
		width: 206px;
		padding: 7px 27px;
		background-color: $orange;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
		text-transform: uppercase;

		i {
			margin-right: 10px;
		}

		&:hover,
		&:active,
		&:focus,
		&:active:visited,
		&:active:focus,
		&:active:hover {
			background-color: $smooth_grey;
			color: $black_grey;
		}
	}

	.demarches-links {
		position: absolute;
		top: 35px;
		right: 0;
		background-color: $white;
		border-left: 1px solid $orange;
		display: block;
		width: 206px;
		padding: 30px 0;
		display: none;
		overflow-y: auto;

		> p {
			text-align: center;
			font-family: "montserratlight";
			font-size: 14px;
			color: $black_blue;
		}

		.logo {
			cursor: pointer;
			max-width: 130px;
			width: 100%;
			display: block;
			padding: 30px 0;
			margin: auto;

			img {
				max-width: 100%;
				max-height: 60px;
				margin: auto;
				display: block;
			}
		}
	}

	.container {
		.info-rdv {
			background-color: $white;
			padding: 40px 25px 10px 25px;

			.separateur {
				padding-top: 30px;
			}

			p {
				font-family: $p_montserrat_bold;
				font-size: $pfs_contenuR_pd;
				color: $black_blue;
			}

			.boutton {
				padding-top: 10px;

				.btn-color {
					background-color: $light_grey !important;
					border: solid 1px $smooth2_grey;
					max-width: 350px;
					margin: auto;
					@include transition(all 0.2s ease-in-out);

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						background-color: $grey !important;
					}
				}

				> img {
					max-width: 100%;
					margin: auto;
					margin-top: 15px;
				}
			}

			//			.ombre-small {
			//				position: relative;
			//				padding-top: 20px;
			//			}
			ul li {
				font-size: 100%;
				color: $black_blue;
				display: list-item;
				list-style-image: url(../images/puce.png);
			}
		}

		.onglet {
			margin-bottom: 120px;

			.nav {
				font-family: $p_montserrat_bold;
				font-size: $pfs_contenuR_pd;

				&[id="offres"] {
					margin-top: 90px;
				}

				li {
					margin-bottom: -1px;
					margin-left: 30px;

					&.active {
						a {
							background-color: $grey_ariane;
						}
					}

					a {
						font-family: $p_montserrat_bold;
						font-size: $pfs_contenuR_pd;
						text-decoration: none;
						color: $black_blue;

						&:focus {
							background-color: $grey_ariane;
						}
					}
				}

				li + li {
					margin-left: 0px;
				}
			}

			.tab-pane {
				.tab-ctn {
					padding-bottom: 50px;
					border: 1px solid $smooth2_grey;
					margin-bottom: 50px;

					.titre-onglet {
						background-color: $white;
						padding: 60px 0px 20px 30px;
						position: relative;

						h1 {
							font-family: $p_montserrat_light;
							margin-top: 0;
							margin-left: 85px;

							span {
								font-family: $p_montserrat_bold;
							}
						}

						img {
							position: absolute;
							left: 9px;
							top: 70px;
							width: 87px;
							height: auto;
						}
					}

					.menu-filtre {
						.btn-primary {
							padding: 3px 10px;
							font-family: $p_montserrat_light;
							font-size: $pfs_contenuR_p;
							color: $grey;
						}

						.active {
							color: $white;
							background-color: $black_blue;
						}

						.sante {
							background-color: $green;
							padding: 0px 0px 10px 0;
						}

						.emploi {
							background-color: $yellow;
							padding: 0px 0px 20px 30px;
						}

						.formation {
							background-color: $orange;
							padding: 0px 0px 10px 0;
						}

						.logement {
							background-color: $blue;
							padding: 0px 0px 10px 0;
						}

						.orientation {
							background-color: $red;
							padding: 0px 0px 10px 0;
						}

						.mobilite {
							background-color: $purple;
							padding: 0px 0px 10px 0;
						}

						.garantie {
							background-color: $black_blue;
							padding: 0px 0px 10px 0;
						}

						.ema {
							background-color: $black_blue;
							padding: 0px 0px 10px 0;
						}
					}

					.offre {
						border-left: 1px solid $black_blue;
						margin-top: 45px;
						position: relative;
						margin-bottom: 20px;

						&.emploi {
							border-left: 1px solid $yellow;

							.titre-texte {
								border-top: 15px solid $yellow;
								background-color: rgba($yellow, .05);

								.categorie {
									color: $yellow;
								}
							}
						}

						&.formation {
							border-left: 1px solid $orange;

							.titre-texte {
								border-top: 15px solid $orange;
								background-color: rgba($orange, .05);

								.categorie {
									color: $orange;
								}
							}
						}

						&.sante {
							border-left: 1px solid $green;

							.titre-texte {
								border-top: 15px solid $green;
								background-color: rgba($green, .05);

								.categorie {
									color: $green;
								}
							}
						}

						&.logement {
							border-left: 1px solid $blue;

							.titre-texte {
								border-top: 15px solid $blue;
								background-color: rgba($blue, .05);

								.categorie {
									color: $blue;
								}
							}
						}

						&.orientation {
							border-left: 1px solid $red;

							.titre-texte {
								border-top: 15px solid $red;
								background-color: rgba($red, .05);

								.categorie {
									color: $red;
								}
							}
						}

						&.mobilite {
							border-left: 1px solid $purple;

							.titre-texte {
								border-top: 15px solid $purple;
								background-color: rgba($purple, .05);

								.categorie {
									color: $purple;
								}
							}
						}

						.titre-texte {
							border-top: 15px solid $black_blue;
							background-color: rgba($black_blue, .05);
							padding: 20px 0px 40px 20px;

							.column-1 {
								padding-bottom: 8px;
								padding-right: 15px;
							}

							.categorie {
								color: $black_blue;
								height: auto;
							}

							p {
								height: 100px;
								overflow: hidden;
								margin-bottom: 0;
							}

							h3 {
								height: 50px;
								overflow: hidden;
								padding-top: 3px;
								padding-bottom: 3px;
								font-family: $p_montserrat_bold;
							}
						}

						.precision {
							padding-left: 15px;
							padding-top: 20px;

							p {
								font-size: $pfs_contenuR_pd;
								margin: 10px 0 10px 0;

								strong {
									color: $black_blue;
								}

								.limite {
									color: $orange;
								}
							}

							+ a.btn-primary {
								margin-left: 15px;
							}
						}

						&.mobilite {
							border-left: 1px solid $purple;
						}
					}

					.boutton {
						height: 188px;
						@include display-flex;
						@include flex-direction;
						@include justify-content(center);
						@include align-items(center);

						.btn-primary {
							position: relative;
							padding: 8px 40px;
							text-decoration: none;
							margin-top: -10px;
							background-color: $green;
							color: $white;

							.flech-ombre {
								position: absolute;
								bottom: -39px;
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}

					.ombre2 {
						bottom: -21px;
					}

					.bottom_liste_item {
						background-image: $bgImg_bottom_liste_item;
						background-position: bottom left;
						background-repeat: repeat-x;
						position: absolute;
						bottom: 0;
						left: 0;
						height: 3px;
						width: 100%;
					}
				}
			}
		}
	}
}

@media #{$media_0_991} {
	section.liste-offre {
		.container {
			.info-rdv {
				.separateur {
					padding-top: 50px;
				}

				.boutton {
					padding-top: 0px;
				}
			}

			.onglet {
				.tab-pane {
					.tab-ctn {
						.titre-onglet {}

						.offre {
							.filet {
								height: 0px;
							}

							.precision {
								height: auto;
							}

							.left-0 {
								padding-left: 0px;
							}

							.boutton {
								height: 150px;

								.btn-primary {
									padding: 8px 40px;
									margin-top: -20px;
								}

								.flech-ombre {}
							}

							.ombre2 {
								bottom: -21px;
							}
						}
					}
				}
			}
		}
	}
}



@media #{$media_0_767} {
	section.liste-offre {
		.container {
			.info-rdv {
				.separateur {
					padding-top: 50px;
				}

				.boutton {
					padding-top: 30px;

					.btn-color {}
				}

				.ombre-small {
					position: relative;
					padding-top: 20px;
				}
			}

			.onglet {
				margin-bottom: 120px;

				.nav {}

				.tab-pane {
					.tab-ctn {
						.titre-onglet {}

						.menu-filtre {}

						.offre {
							.titre-texte {
								.column-1 {
									border-right: 0px solid $black_blue;
								}

								h3 {}

								.categorie {
									color: $red;
								}
							}

							.precision {
								margin-bottom: 50px;
							}

							.filet {}

							.left-0 {
								padding-left: 0px;
							}

							.boutton {
								height: 100px;

								.btn-primary {
									padding: 8px 40px;
								}

								.flech-ombre {}
							}

							.ombre2 {
								bottom: -21px;
							}
						}
					}
				}
			}
		}
	}
}


/* pagination */

.pagination-container {
	@include display-flex;
	@include flex-direction-row;
	@include justify-content(center);
	@include align-items(center);

	.pagination {
		margin: 10px 0 20px;
		@include display-flex;
		@include flex-direction-row;
		@include justify-content(center);
		@include align-items(center);

		> li {
			vertical-align: middle;

			&:last-child,
			&:first-child {
				a {}
			}

			> a {
				padding: 0;
				border: 0;
				float: none;
				height: 43px;
				width: 43px;
				@include display-flex;
				@include flex-direction-row;
				@include justify-content(center);
				@include align-items(center);
				vertical-align: middle;
				border: 1px solid $smooth_grey;
				color: $grey;
				border-radius: 7px;
				@include transition(all 0.2s ease-in-out);
				font-family: $p_montserrat_light;

				&:hover,
				&:active,
				&:focus,
				&:active:focus,
				&:active:hover {
					background-color: $smooth_grey;
					font-family: $p_montserrat_light;
				}
			}

			p {
				color: $grey;
				font-family: $p_montserrat_bold;
				text-transform: uppercase;
				margin-bottom: 0;
				text-align: center;
			}

			&.prev {
				margin-right: 40px;

				p {
					display: none;
				}
			}

			&.active {

				height: 43px;
				width: 43px;
				@include display-flex;
				@include flex-direction-row;
				@include justify-content(center);
				@include align-items(center);
				background-color: $smooth_grey;
				border: 1px solid $smooth_grey;
				border-radius: 7px;
				color: $black;

			}

			&.next {
				margin-left: 40px;

				p {
					display: none;
				}
			}
		}
	}
}

p.mention {
	font-family: $p_montserrat_light;
	font-size: 14px;
	color: $grey;
	text-align: center;

	span {
		font-family: $p_montserrat_bold;
	}
}

@media#{$media_0_991} {
	.pagination-container {
		.pagination {
			> li {
				&.link {

					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(14),
					&:nth-child(15) {
						display: none;
					}
				}
			}
		}
	}
}

@media#{$media_0_767} {
	.pagination-container {
		.pagination {
			> li {
				&.link {
					display: none;
				}
			}
		}
	}
}


/* logo footer */

section.logo-footer {
	> img {
		margin: auto;
		display: block;
		margin-bottom: 15px;
		max-width: 100%;
	}

	.row {
		border-top: 1px solid $smooth2_grey;
		margin: 0;

		.container {
			@include display-flex;
			@include justify-content(center);

			.logo {
				height: 150px;
				width: 16.6666666667%;
				padding: 30px 15px;
				@include display-flex;
				@include flex-direction;
				@include justify-content(center);
				@include align-items(center);

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		& + .row {
			border-top: 1px solid $light_grey;
		}
	}
}

@media #{$media_0_767} {
	section.logo-footer {
		display: none;

		> img {}

		.row {
			.container {
				.logo {
					img {}
				}
			}

			& + .row {}
		}
	}
}

//--input type file--//
/* styles de base si JS est activé */

.js .input-file-container {
	position: relative;
	display: inline-block;
	width: auto;
	margin-top: 15px;
}

.js .input-file-trigger {
	display: block;
	padding: 8px 20px;
	@include degrade_file_btn;
	color: $c_input_trigger;
	border: 1px solid $bordc_input_trigger;
	font-family: $p_montserrat_bold;
	text-transform: uppercase;
	font-size: 1em;
	@include transition(all .4s);
	cursor: pointer;
	text-align: center;
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	margin: 0;
}

.js .input-file {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	padding: 14px 0;
	opacity: 0;
	cursor: pointer;
}


/* quelques styles d'interactions */

.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
	//    background: $bg_input_trigger;
	@include degrade_file_btn;
	color: $c_input_trigger;
	border: 1px solid $bordc_input_trigger_focus;
}


/* styles du retour visuel */

.file {
	display: inline-block;
	background-color: $white;
	width: 262px;
	height: 38px;
	padding: 8px 20px;
	border: 1px solid $bordc_input_trigger;
	margin-top: 15px;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}

.file-return {
	margin: 0;
	width: 220px;
	height: 14px;
	overflow: hidden;
}

//.file-return:not(:empty) {
//    margin: 1em 0;
//}
.js .file-return {
	font-family: $p_montserrat_bold;
	font-style: italic;
	font-size: .9em;
	font-weight: bold;
}


/* footer */

footer {
	background-color: $bg_footer;

	.container {
		padding-top: 50px;
		padding-bottom: 80px;

		.adress {
			.villeneuve {
				padding-bottom: 30px;
				border-bottom: 1px solid $bordc_footer_adress;
				border-top: 1px solid $bordc_footer_adress;
			}

			.fumel {
				padding-bottom: 30px;
				border-top: 1px solid $bordc_footer_adress;
				border-bottom: 1px solid $bordc_footer_adress;
			}

			.villereal {
				padding-bottom: 30px;
				border-bottom: 1px solid $bordc_footer_adress;
			}

			h1 {
				color: $c_footer_adress_h1;
				font-family: $p_montserrat_bold;
				font-size: $pfs_footer_adress_h1;
			}

			p {
				color: $c_footer_adress_p;
				font-family: $p_montserrat_light;
				font-size: $pfs_footer_adress_p;
				line-height: $plh_footer_adress_p;
				margin: 0;
			}

			a {
				margin-top: 8px;
				display: block;
				text-decoration: none;
				@include transition(all 0.2s ease-in-out);

				&:hover,
				&:active,
				&:focus,
				&:active:visited,
				&:active:focus,
				&:active:hover {
					text-decoration: underline;
					color: $c_footer_adress_a_hover;
					font-family: $p_montserrat_light;

					p {
						color: $c_footer_adress_a_hover;
						font-family: $p_montserrat_light;
					}
				}
			}

			.phone {
				margin-top: 8px;
				font-family: $p_montserrat_bold;
				font-size: $pfs_footer_adress_phone;
			}

			.reseaux {
				@include display-flex;
				@include flex-direction-between;

				a {
					text-align: center;
					text-decoration: none;
					margin-top: 0;

					img {
						margin-bottom: 40px;
					}

					i {
						color: $c_footer_logo_i;
						font-size: $pfs_footer_logo_i;
						margin-top: 15px;
					}

					p {
						font-family: $p_montserrat_light;
						font-size: $pfs_footer_logo_p;
						color: $c_footer_logo_p;
						text-align: center;
					}
				}

				.snapchat {
					margin-top: 19px;

					img {
						width: 57px;
						margin: auto;
					}

					p {
						font-family: $p_montserrat_light;
						font-size: $pfs_footer_logo_p;
						color: $c_footer_logo_p;
						text-align: center;
						margin-top: 5px;
					}
				}
			}
		}

		.services {
			h2 {
				color: $c_footer_services_h2;
				font-size: $pfs_footer_services_h2;
				font-family: $p_montserrat_bold;
				margin-top: 0;

				span {
					font-family: $p_montserrat_light;
				}
			}

			ul {
				list-style-image: $bgImg_footer_ul;
				padding-left: 15px;

				li {
					margin-bottom: 8px;

					a {
						display: block;
						text-decoration: none;
						@include transition(all 0.2s ease-in-out);

						p {
							font-family: $p_montserrat_light;
							color: $c_footer_services_p;
							font-size: $pfs_footer_services_p;
							margin: 0;
						}

						&:hover,
						&:active,
						&:focus,
						&:active:visited,
						&:active:focus,
						&:active:hover {
							text-decoration: none;

							p {
								color: $c_footer_services_p_hover;
							}
						}
					}
				}

				& + ul {
					margin-top: 30px;
				}
			}
		}

		.logo {
			@include display-flex;
			@include flex-direction;
			@include justify-content(center);
			@include align-items(center);

			a {
				margin-bottom: 15px;
			}
		}

		.reseaux {
			@include display-flex;
			@include flex-direction-between;

			a {
				text-align: center;
				text-decoration: none;
				margin-top: 0;

				img {
					margin-bottom: 40px;
				}

				i {
					color: $c_footer_logo_i;
					font-size: $pfs_footer_logo_i;
					margin-top: 15px;
				}

				p {
					font-family: $p_montserrat_light;
					font-size: $pfs_footer_logo_p;
					color: $c_footer_logo_p;
					text-align: center;
				}
			}

			.snapchat {
				margin-top: 19px;

				img {
					width: 57px;
					margin: auto;
				}

				p {
					font-family: $p_montserrat_light;
					font-size: $pfs_footer_logo_p;
					color: $c_footer_logo_p;
					text-align: center;
					margin-top: 5px;
				}
			}
		}
	}
}

@media #{$media_0_1199} {
	footer {
		.container {
			.reseaux {
				@include flex-direction;
			}
		}
	}
}

@media #{$media_0_991} {
	footer {
		.container {
			.adress {
				.villeneuve {
					border-bottom: none;
				}
			}

			.services {
				margin-bottom: 50px;
			}

			.reseaux {
				margin-top: 45px;
				@include display-flex;
				@include flex-direction-row;
				@include flex-direction-between;
			}
		}
	}
}

@media #{$media_0_767} {
	footer {
		.container {
			.adress {
				margin-bottom: 50px;
			}

			.logo {
				@include display-flex;
				@include flex-direction-row;
				@include flex-direction-between;
				@include flex-wrap;
			}
		}
	}
}


/* sub-footer */

.sub-footer {
	background-color: $bg_subFooter;
	font-family: $p_montserrat_reg;
	font-size: $pfs_subFooter;

	.container {
		padding: 15px;

		.mentions {
			width: 100%;

			.droits {
				float: left;
			}

			.crea {
				float: right;
			}

			p {
				color: $c_subFooter_p;
				display: inline-block;
				margin: 0;
			}

			a {
				display: inline-block;

				p {
					color: $c_subFooter_a;
				}
			}
		}
	}
}

@media #{$media_0_1199} {
	.sub-footer {
		.container {
			.mentions {
				.droits {
					float: none;
					margin: auto;
					text-align: center;
				}

				.crea {
					float: none;
					margin: auto;
					text-align: center;
					margin-top: 15px;
				}
			}
		}
	}
}

@media #{$media_0_767} {
	.sub-footer {
		.container {
			padding: 15px 0;

			.mentions {
				.droits {
					margin-left: 15px;
				}

				.crea {
					margin-left: 15px;
				}

				p {
					display: block;
				}

				a {
					display: block;
				}
			}
		}
	}
}

section.galerie {
	background-color: $light_grey;
	margin: 0px 0px 40px 0px;

	.container {
		background-color: $white;

		h3 {
			color: $pfs_contenuR_h3;
		}

		.en-image {
			.thumbnails {
				a {
					display: block;
					line-height: 0;
					height: 190px;
					overflow: hidden;
					position: relative;
					@include transition(all 0.2s ease-in-out);

					&:hover,
					&:active,
					&:focus,
					&:active:visited,
					&:active:focus,
					&:active:hover {
						outline: none;

						.filtre_hover {
							background-color: $bg_galerie_hover_filtre;
							z-index: 1;

							i {
								display: inline-block;
							}
						}

						.legende {
							background-color: $bg_galerie_legende_hover;
							z-index: 20;
						}
					}

					img {
						width: 100%;
					}

					.filtre_hover {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						@include display-flex;
						@include flex-direction;
						@include justify-content(center);
						@include align-items(center);

						i {
							display: none;
							color: $c_galerie_hover_filtre_i;
							font-size: $pfs_galerie_hover_filtre_i;
						}
					}
				}

				& + .thumbnails + .thumbnails + .thumbnails {
					margin-top: 20px;
				}
			}
		}
	}
}

@media#{$media_0_991} {
	section.galerie {
		.container {
			h3 {}

			.en-image {
				.thumbnails {
					a {
						height: 165px;
					}

					& + .thumbnails + .thumbnails {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

@media#{$media_0_767} {
	section.galerie {
		.container {
			h3 {}

			.en-image {
				.thumbnails {
					a {
						height: auto;
					}

					& + .thumbnails + .thumbnails {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

section.formulaire {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;
	padding-top: 0px;

	.container {
		padding: 0px 0px;
		margin-bottom: 50px;
		position: relative;

		form {
			.input-group {
				width: 100%;
				height: 70px;

				&.textarea {
					height: auto;
				}

				input {
					margin-bottom: 30px;
				}
			}

			&[action="contact"] {
				textarea {
					min-height: 244px;
				}
			}
		}
	}

	&.employeur {
		.container {
			form {
				h4 {
					margin-bottom: 9px;
				}
			}
		}
	}
}


/* formulaire de connexion */

section.choix-connexion {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;
	padding-top: 0px;

	.infos {
		p {
			font-family: $p_montserrat_bold;
			font-size: $pfs_contenuR_pd;
			color: $black_blue;
		}

		ul li {
			font-size: 100%;
			color: $black_blue;
			display: list-item;
			list-style-image: url(../images/puce.png);
		}
	}
}

@media#{$media_0_767} {
	section.choix-connexion {
		.container {
			.btn-color {
				max-width: 350px;
				margin: auto;
				margin-bottom: 15px;
			}
		}
	}
}

section.connexion {
	.container {
		.block {
			form {
				.text-center {
					height: 256px;
					@include display-flex;
					@include flex-direction;
					@include justify-content(center);
					@include align-items(center);

					[type="submit"] {
						margin: 0;
					}
				}
			}
		}
	}
}

section.choix-cons {
	background-image: $bgImg_degrade;
	background-repeat: repeat-x;
	background-position: top;
	padding-top: 0px;

	.container {
		.block {
			form {
				h3 {
					padding: 0 15px;
					margin: 0;
					margin-bottom: 30px;
				}

				> div {
					input {
						width: 100%;
					}
				}

				.btn-primary-form-valid {
					background-color: $white;
					color: $black_blue;
					border: 1px solid $smooth2_grey;
					@include transition(all 0.2s ease-in-out);

					&:hover {
						background-color: $orange;
						color: $white;
						border: 1px solid $smooth2_grey;
					}
				}
			}
		}
	}
}

section.map {
	background-color: $light_grey;
	margin: 100px 0px;

	.container {
		background-color: $white;

		h2 {
			font-family: $p_montserrat_bold;
			font-size: $pfs_contact_h2;
			color: $blue;
		}

		p {
			font-family: $p_montserrat_light;
			font-size: $pfs_contact_p;
		}

		span {
			font-family: $p_montserrat_bold;
			font-size: $pfs_contact_span;
		}

		a {
			color: $orange;
			@include transition(all 0.2s ease-in-out);

			&:hover,
			&:active,
			&:focus,
			&:active:visited,
			&:active:focus,
			&:active:hover {}
		}

		.marg_bottom {
			margin-bottom: 20px;
		}
	}
}

@media#{$media_0_767} {
	section.map {
		margin: 20px 0px;
	}
}


/* Page temporaire */

.temp {
	.main {
		text-align: center !important;
		height: 92vh;
		padding: 120px 30px 0;
		@include display-flex;
		@include flex-direction;
		@include justify-content(center);
		@include align-items(center);

		[id=getting-started] {
			font-family: $p_montserrat_light;
			color: $orange;
			font-size: $pfs_contenuR_h1;
			margin-top: 20px;
		}

		* {
			text-align: center !important;
		}
	}
}