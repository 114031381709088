/* font-family */


/* Lobster */

$p_lobster: "lobster_regular",
sans-serif;

/* Montserrat */

$p_montserrat_extraLight: "montserratextralight",
sans-serif;
$p_montserrat_light: "montserratlight",
sans-serif;
$p_montserrat_reg: "montserratregular",
sans-serif;
$p_montserrat_bold: "montserratbold",
sans-serif;

/* Open Sans */

$p_open_bold: "open_sansbold",
sans-serif;
$p_open_reg: "open_sansregular",
sans-serif;
$p_open_light: "open_sanslight",
sans-serif;

/* Work Sans */

$p_work_bold: "work_sansbold",
sans-serif;
$p_work_light: "work_sanslight",
sans-serif;
$p_work_semiBold: "work_sanssemibold",
sans-serif;

/* font-size */


/* contenu général */
$pfs_texte-courant: 14px; //Texte des liens du bandeau de connexion

//$pfs_h1: 49px; //h1

/* header */

$pfs_header_menu_btns: 14px; //Texte des liens du bandeau de connexion
$pfs_header_menu_ul: 14px; //Texte de liens du menu déroulant du header

/* footer */

$pfs_footer_adress_h1: 21px; //Titre des blocs adress du footer
$pfs_footer_adress_p: 18px; //Texte des blocs adress du footer
$plh_footer_adress_p: 21px; //Texte des blocs adress du footer
$pfs_footer_adress_phone: 21px; //Texte du numéro de téléphone des blocs adress du footer
$pfs_footer_services_h2: 30px; //Titre du bloc des services du footer
$pfs_footer_services_p: 16px; //Texte du bloc des services du footer
$pfs_footer_logo_i: 66px; //Picto facebook du footer
$pfs_footer_logo_p: 12px; //Texte sous le picto facebook du footer

/* sub-footer */

$pfs_subFooter: 12px; //Texte du sub-footer
///* head */
//
//$pfs_head_h1: 28px; //"Accueil, information, orientation, accompagnement" du head
//$pfs_head_h1_xs: 20px; //"Accueil, information, orientation, accompagnement" du head ( @media 0 -> 768 )
//$pfs_head_h2: 23px; //"Pour les jeunes de 16 à 26 ans" du head
//$pfs_head_h2_xs: 20px; //"Pour les jeunes de 16 à 26 ans" du head ( @media 0 -> 768 )
//$pfs_head_p: 20px; //Texte des boutons du head
//$pfs_head_rdv_p: 20px; //Texte du bouton rdv-conseiller-perso
//$pfs_head_p_xs: 14px; //Texte des boutons du head ( @media 0 -> 768 )
//$pfs_head_p_first: 24px; //Texte de la 1ère ligne des boutons du head
//$pfs_head_p_first_xs: 16px; //Texte de la 1ère ligne des boutons du head ( @media 0 -> 768 )
//
///* section quiSommesNous */
//
//$pfs_quiSommesNous_h3: 22px; //Texte de gauche de la section quiSommesNous
//$pfs_quiSommesNous_p: 15px; //Texte de droite de la section quiSommesNous
//
///* section services */
//
//$pfs_services_h3: 18px; //Texte des intitulés de la section nosServices
//$pfs_services_p: 14px; //Texte de description de la section nosServices
//
///* section stats */
//
//$pfs_stats_h1: 28px; //Texte du titre de la section statistiques
//$pfs_stats_h1_xs: 20px; //Texte du titre de la section statistiques ( @media 0 -> 768 )
//$pfs_stats_nb: 70px; //Texte des nombres de la section statistiques
//$pfs_stats_nb_xs: 40px; //Texte des nombres de la section statistiques ( @media 0 -> 768 )
//$pfs_stats_nb_intitules: 17px; //Texte des intitulés de la section statistiques
//
///* section notreActualite */
//
//$pfs_notreActualite_date: 14px; //Texte de la date d'une actualité de la section notreActualité
//$pfs_notreActualite_h2: 19px; //Texte du titre d'une actualité de la section notreActualité
//$pfs_notreActualite_amorce: 14px; //Texte de l'amorce d'une actualité de la section notreActualité
//$pfs_notreActualite_tout: 18px; //Texte du lien "toutes les actualités" de la section notreActualité
//
///* section coordonnees */
//
//$pfs_coordonnees_h1: 33px; //Texte du titre de la section coordonnees
//$pfs_coordonnees_p: 14px; //Texte des informations d'horaires, adresses et numéro de téléphone de la section coordonnees
//$pfs_coordonnees_p_strong: 18px; //Texte des titres horaires, adresses et numéro de téléphone de la section coordonnees
//
///* footer */
//
//$pfs_footer_h4: 14px; //Texte des titres des liens du footer
//$pfs_footer_a: 12px; //Texte des liens du footer
//$pfs_footer_newsletter_p: 14px; //Texte de la partie newsletter du footer
//$pfs_footer_newsletter_input: 14px; //Texte de l'input de la partie newsletter du footer
//
///* page de contenu rédactionnel */

$pfs_contenuR_h1: 45px; //Texte des <h1> des pages de contenus rédactionnel
$pfs_contenuR_h1resp: 35px; //Texte des <h1> des pages de contenus rédactionnel
$pfs_contenuR_h2: 30px; //Texte des <h2> des pages de contenus rédactionnel
$pfs_contenuR_h3: 24px; //Texte des <h3> des pages de contenus rédactionnel
$pfs_contenuR_h4: 18px; //Texte des <h4> des pages de contenus rédactionnel
$pfs_contenuR_h5: 18px; //Texte des <h5> des pages de contenus rédactionnel
$pfs_contenuR_h6: 14px; //Texte des <h6> des pages de contenus rédactionnel
$plh_contenuR_h1: 45px; //Texte des <h1> des pages de contenus rédactionnel
$plh_contenuR_h2: 34px; //Texte des <h2> des pages de contenus rédactionnel
$plh_contenuR_h3: 28px; //Texte des <h3> des pages de contenus rédactionnel
$plh_contenuR_h4: 22px; //Texte des <h4> des pages de contenus rédactionnel
$plh_contenuR_h5: 22px; //Texte des <h5> des pages de contenus rédactionnel
$plh_contenuR_h6: 22px; //Texte des <h6> des pages de contenus rédactionnel
$pfs_contenuR_p: 14px; //Texte des <p> des pages de contenus rédactionnel
$pfs_contenuR_pd: 15px; //Texte des <p> des details des offres
$plh_contenuR_p: 22px; //Texte des <p> des pages de contenus rédactionnel
$pfs_contenuR_ul: 16px; //Texte des <ul> des pages de contenus rédactionnel
$plh_contenuR_ul: 22px; //Texte des <ul> des pages de contenus rédactionnel
$pfs_contenuR_ol: 16px; //Texte des <ol> des pages de contenus rédactionnel
$pfs_contenuR_ol_before: 16px; //Puce des <ol> des pages de contenus rédactionnel
$plh_contenuR_ol: 22px; //Texte des <ol> des pages de contenus rédactionnel
$pfs_galerie_hover_filtre_i: 35px; //Picto du filtre des images de la galerie au survol

///* page de contact */
$pfs_contact_h1: 45px; //Titre de la page de contact
$pfs_contact_h2: 20px;
$pfs_contact_p: 14px;
$pfs_contact_span: 22px;
$pfs_contact_h2_xs: 28px; //Titre de la page de contact (@media 0 -> 767)
$pfs_contact_label: 14px; //Texte des labels de la page de contact
$pfs_contact_mention: 12px; //Texte de la mention du formulaire de la page de contact
//$pfs_contact_aside_mission: 19px; //Texte "Mission Locale de l'Agenais, de l'Albret et du Confluent" de l'aside la page de contact
//$pfs_contact_aside_adress: 14px; //Texte "70, Boulevard Sylvain Dumon 47000 AGEN" de l'aside la page de contact
//$pfs_contact_aside_tel: 18px; //Texte "Tél : 05 53 47 23 32" de l'aside la page de contact
//$pfs_contact_aside_mail: 14px; //Texte "e-mail : siege@mission-locale-agen.org" de l'aside la page de contact
//$pfs_contact_aside_horaires: 24px; //Texte "Horaires d'Ouverture" de l'aside la page de contact
//$pfs_contact_aside_horaires_titre: 14px; //Texte des dates d'ouverture de l'aside la page de contact
$pfs_contact_button: 14px; //Texte des bouttons


///* formulaire de connexion */
//
//$pfs_connexion_h1: 60px; //Titre "connexion" du formulaire du connexion
//$pfs_connexion_input: 14px; //Texte des inputs du formulaire du connexion
//$pfs_connexion_input_placeholder: 14px; //Texte des placeholder des inputs du formulaire du connexion
//$pfs_connexion_button: 14px; //Texte du bouton du formulaire du connexion
//$pfs_inscription_titre: 16px; //Texte "situation professionnelle" du formulaire d'inscription
//
///* Liste des modules */
//
//$pfs_emplois_h2: 25px; //Texte des liens de la page d'accueil dédiée des services
//
///* Liste annonces */
//
//$pfs_listAnnonce_ajout: 12px; //Texte date d'ajout des listes d'annonces
//$pfs_listAnnonce_h3_new: 16px; //Texte "nouveau" des listes d'annonces
//$pfs_listAnnonce_categorie: 18px; //Texte de la catégorie des annonces de liste d'annonces
//$pfs_listAnnonce_nbplaces: 32px; //Texte du nombre de place restantes des annonces de liste d'annonces
//
///* Form rdv conseiller */
//
//$pfs_rdv_h5: 18px; //Texte du titre "étape 1..." des formulaire de rendez-vous avec un conseiller
//
///* menu des services */
//
//$pfs_services_i: 60px;

/* colors */

//$purple: #80508c;
//$light_purple: #a24794;
//$pink: #cc62c2;
//$light_pink: #ffb6f7;
//$light_orange: rgba(253, 143, 34, 0.20);
//$light_grey: #8e8e8e;
$filtre: rgba(0, 0, 0, 0.56);
//$red: #ef4141;
//$smooth_grey_txt: #c9c9c9;
//$red_noPlace: rgba(255, 115, 112, 0.72);
$red: #d41052;
$orange: #fc942d;
$yellow: #febe2a;
$green: #8dc62a;
$blue: #2397d4;
$purple: #9e1d61;
$transparent: rgba(255, 255, 255, 0);
$forty_black: rgba(51, 51, 51, 0.45);
$black: #333333;
$white: #fff;
$grey: #989898;
$black_grey: #4d4d4d;
$smooth_grey: #e5e5e5;
$smooth2_grey: #cccccb;
$light_grey: #f1f1f1;
$grey_ariane: #dadada;
$black_blue: #0b3551;
$blue_grey: #9daeb9;
$head_grey: #f6f6f6;


/* contenu général */

//$c_h1: $purple; //h1
//
///* body txt */
//
//$c_body: $black_grey; //Texte par défaut de body
//
///* addthis */
//
//$bg_addthis: $pink; //Fond du bouton addthis
//

/* btn-primary */

$c_btnPrimary:$black_blue; //Texte du bouton primary
$c_btnPrimary_hover:$white; //Texte du bouton primary au survol
$c_btnPrimary_active:$white; //Texte du bouton primary actif
$bordc_btnPrimary:$smooth2_grey; //Bordure du bouton primary
$bordc_btnPrimary_hover:$smooth2_grey; //Bordure du bouton primary au survol
$bordc_btnPrimary_active:$smooth2_grey; //Bordure du bouton primary actif
$bg_btnPrimary:$white; //Fond du bouton primary
$bg_btnPrimary_hover:$orange; //Fond du bouton primary au survol
$bg_btnPrimary_active:$orange; //Fond du bouton primary actif
///* btn-2 */
//
//$c_btn2:$white; //Texte du bouton primary
//$c_btn2_hover:$orange; //Texte du bouton primary au survol
//$bordc_btn2: $orange; //Bordure du bouton primary
//$bordc_btn2_hover: $orange; //Bordure du bouton primary au survol
//$bg_btn2: $orange; //Fond du bouton primary
//$bg_btn2_hover: $transparent; //Fond du bouton primary au survol
//
///* btn-pink */
//
//$c_btnPink:$white; //Texte du bouton primary
//$c_btnPink_hover:$pink; //Texte du bouton primary au survol
//$bordc_btnPink: $pink; //Bordure du bouton primary
//$bordc_btnPink_hover: $pink; //Bordure du bouton primary au survol
//$bg_btnPink: $pink; //Fond du bouton primary
//$bg_btnPink_hover: $transparent; //Fond du bouton primary au survol
//
///* btn-inscrire */
//
//$c_btnInscrire:$white; //Texte du bouton primary
//$c_btnInscrire_hover:$orange; //Texte du bouton primary au survol
//$bordc_btnInscrire: $orange; //Bordure du bouton primary
//$bordc_btnInscrire_hover: $orange; //Bordure du bouton primary au survol
//$bg_btnInscrire: $orange; //Fond du bouton primary
//$bg_btnInscrire_hover: $transparent; //Fond du bouton primary au survol
//
///* btn-valider */
//
//$c_btnValider:$white; //Texte du bouton primary
//$c_btnValider_hover:$pink; //Texte du bouton primary au survol
//$bordc_btnValider: $pink; //Bordure du bouton primary
//$bordc_btnValider_hover: $purple; //Bordure du bouton primary au survol
//$bg_btnValider: $pink; //Fond du bouton primary
//$bg_btnValider_hover: $white; //Fond du bouton primary au survol
//$c_btnValider_hover_well:$pink; //Texte du bouton primary au survol dans le collapse
//$bordc_btnValider_hover_well: $white; //Bordure du bouton primary au survol dans le collapse
//$bg_btnValider_hover_well: $white; //Fond du bouton primary au survol dans le collapse
//
///* input type file */
//
//$bg_input_trigger: $purple; //Fond bouton selection de fichier
//$c_input_trigger: $white; //Texte bouton selection de fichier
//$bg_input_trigger_focus: $white; //Fond bouton selection de fichier en focus
//$c_input_trigger_focus: $purple; //Text bouton selection de fichier en focus
//$bordc_input_trigger_focus: $purple; //Bordure bouton selection de fichier en focus

/* home page */


/* header */

$bg_header: $black_grey; //Fond du header
$bg_header_container: $black_grey; //Fond du container du header
$bg_header_menu_btn: $transparent; //Fond du bouton du menu du header
$bg_header_menu_btn_picto: $orange; //Fond du picto du bouton du menu du header
$bg_header_menu_btn_picto_hover: $smooth_grey; //Fond du picto du bouton du menu du header au survol
$c_header_menu_btn_txt: $white; //Texte du picto du bouton du menu du header
$bg_header_menu_btn_barre: $white; //Barres du picto du bouton du menu du header
$bg_header_menu_btn_barre_hover: $black_grey; //Barres du picto du bouton du menu du header au survol
$bg_header_menu_rdv: $orange; //Fond du bouton de rendez-vous du header
$bg_header_menu_rdv_hover: $smooth_grey; //Fond du bouton de rendez-vous du header au survol
$c_header_menu_rdv_i: $black_grey; //Picto du bouton de rendez-vous du header
$c_header_menu_rdv_p: $white; //Texte du bouton de rendez-vous du header
$c_header_menu_rdv_p_hover: $black_grey; //Texte du bouton de rendez-vous du header au survol
$c_header_menu_btns: $white; //Texte des boutons à droite dans le header
$c_header_menu_btns_hover: $orange; //Texte des boutons à droite dans le header
$c_header_menu_contact_hover: $black_grey; //Texte du bouton de contact du header au survol
$c_header_menu_contact_i: $orange; //Picto du bouton de contact du header
$c_header_menu_contact_i_hover: $black_grey; //Picto du bouton de contact du header au survol
$bg_header_menu_contact_hover: $smooth_grey; //Fond du bouton de contact du header au survol
$bg_header_menu_login: $orange; //Fond du bouton de connexion / déconnexion du header
$bg_header_menu_login_hover: $smooth_grey; //Fond du bouton de connexion / déconnexion du header au survol
$c_header_menu_login_hover_i: $black_grey; //Picto du bouton de connexion / déconnexion du header au survol
$c_header_menu_login_hover_p: $black_grey; //Texte du bouton de connexion / déconnexion du header au survol
$c_header_menu_ul: $grey; //Texte de liens du menu déroulant du header
$bg_header_menu_ul: $white; //Fond du menu déroulant du header
$bg_header_menu_ul_li: $smooth_grey; //Fond des liens du menu déroulant du header au survol
$c_header_menu_ul_li_orientation: $red; //Texte du lien orientation du menu déroulant du header
$c_header_menu_ul_li_formation: $orange; //Texte du lien formation du menu déroulant du header
$c_header_menu_ul_li_emploi: $yellow; //Texte du lien emploi du menu déroulant du header
$c_header_menu_ul_li_sante: $green; //Texte du lien sante du menu déroulant du header
$c_header_menu_ul_li_mobilite: $purple; //Texte du lien mobilite du menu déroulant du header
$c_header_menu_ul_li_logement: $blue; //Texte du lien logement du menu déroulant du header
$c_header_menu_ul_li_ul_il_a_hover: $black_grey; //Liens des sous-menus du menu déroulant du header au survol
$bg_header_menu_ul_li_ul_il_a_hover: $smooth_grey; //Fond des liens des sous-menus du menu déroulant du header au survol

/* footer */

$bg_footer: $black_grey; //Fond du footer
$bordc_footer_adress: $orange; //Bordure des blocs adress du footer
$c_footer_adress_h1: $blue; //Titre des blocs adress du footer
$c_footer_adress_p: $white; //Texte des blocs adress du footer
$c_footer_adress_a_hover: $white; //Texte des liens des blocs adress du footer au survol
$c_footer_services_h2: $green; //Titre du bloc des services du footer
$c_footer_services_p: $white; //Texte du bloc des services du footer
$c_footer_services_p_hover: $orange; //Texte du bloc des services du footer au survol
$c_footer_logo_i: $white; //Picto facebook du footer
$c_footer_logo_p: $white; //Texte sous le picto facebook du footer

/* sub-footer */

$bg_subFooter: $orange; //Fond du sub-footer
$c_subFooter_p: $black_grey; //Textes du sub-footer
$c_subFooter_a: $white; //Liens du sub-footer


/* input type file */

$c_input_trigger: $black;//Texte bouton selection de fichier
$bg_input_trigger_focus: $white;//Fond bouton selection de fichier en focus
$c_input_trigger_focus: $purple;//Text bouton selection de fichier en focus
$bordc_input_trigger_focus: $smooth2_grey;//Bordure bouton selection de fichier en focus
$bordc_input_trigger: $smooth2_grey;//Bordure bouton selection de fichier en focus


///* head */
//
//$bg_head_filter: rgba(53, 53, 53, 0.10);
//$bg_head_bande_filter: rgba(0, 0, 0, 0.4);
//$c_head_h1: $white; //"Accueil, information, orientation, accompagnement" du head
//$c_head_h2: $white; //"Pour les jeunes de 16 à 26 ans" du head
//$bg_head_a: $orange; //Fond des boutons du head
//$bg_head_a_hover: $transparent; //Fond des boutons du head au survol
//$bordc_head_a_hover: $orange; //Bordure des boutons du head au survol
//$c_head_a: $white; //Texte des boutons du head
//
///* section quiSommesNous */
//
//$bg_quiSommesNous: $orange; //Fond de la section quiSommesNous
//$c_quiSommesNous_picto_h1: $pink; //picto accolé aux titres
//$c_quiSommesNous_h3: $white; //Texte de gauche des paragraphes de la section quiSommesNous
//$c_quiSommesNous_p: $white; //Texte de droite des paragraphes de la section quiSommesNous
//
///* section nosServices */
//
//$bg_nosServices_container: $white; //Fond du conteneur de la section nosServices
//$c_nosServices_h1_picto: $orange; //Picto du <h1> de la section nosServices
//$c_nosServices_h3: $black_grey; //Texte des l'intitulés de la section nosServices
//$c_nosServices_p: $black_grey; //Texte de description de la section nosServices
//
///* section stats */
//
//$c_stats_h1: $white; //Texte des nombres de la section statistiques
//$c_stats_nb: $orange; //Texte des nombres de la section statistiques
//$c_stats_nb_intitules: $white; //Texte des intitulés de la section statistiques
//
///* section notreActualité */
//
//$c_notreActualite_h1_picto: $orange; //Picto du <h1> de la section notreActualité
//$c_notreActualite_date: $light_grey; //Texte de la date d'une actualité de la section notreActualité
//$c_notreActualite_h2: $pink; //Texte du titre d'une actualité de la section notreActualité
//$c_notreActualite_h2_hover: $orange; //Texte du titre d'une actualité de la section notreActualité au survol
//$c_notreActualite_amorce: $black_grey; //Texte de l'amorce d'une actualité de la section notreActualité
//$c_notreActualite_tout: $orange; //Texte du lien "toutes les actualités" de la section notreActualité
//$bordc_notreActualite_bordure: $white; //Bordure de l'actualité de la section notreActualité au survol
//$bordc_notreActualite_bordure_hover: $orange; //Bordure de l'actualité de la section notreActualité au survol
//
///* section coordonnees */
//
//$bg_coordonnees_blockBg: $orange; //Fond du block orange de la section coordonnees
//$c_coordonnees_p: $white; //Texte des informations d'horaires, adresses et numéro de téléphone de la section coordonnees
//
///* footer */
//
//$bg_footer_link: $light_purple; //Fond du block de liens du footer
//$bg_footer_newsletter: $white; //Fond du block de la newsletter du footer
//$c_footer_h4: $orange; //Texte des titres des liens du footer
//$c_footer_a: $white; //Texte des liens du footer
//$c_footer_a_hover: $orange; //Texte des liens du footer au survol
//$c_footer_facebook: $orange; //Picto facebook du footer
//$c_footer_newsletter_h1_picto: $orange; //Picto du <h1> de la partie newsletter du footer
//$bg_footer_newsletter_input: $pink; //Fond de l'input de la partie newsletter du footer
//$c_footer_newsletter_input: $white; //Texte de l'input de la partie newsletter du footer
//$c_footer_newsletter_input_focus: $orange; //Texte du placeholder de l'input de la partie newsletter du footer en focus
//$bg_footer_newsletter_button: $purple; //Fond du bouton de la partie newsletter du footer
//$c_footer_newsletter_button: $white; //Texte du bouton de la partie newsletter du footer
//$bordc_footer_newsletter_button: $purple; //Bordure du bouton de la partie newsletter du footer
//$bg_footer_newsletter_button_hover: $white; //Fond du bouton de la partie newsletter du footer au survol
//$c_footer_newsletter_button_hover: $purple; //Texte du bouton de la partie newsletter du footer au survol
//$bordc_footer_newsletter_button_hover: $purple; //Bordure du bouton de la partie newsletter du footer au survol
//$bg_footer_containerXs_newsletter: $white; //Fond de la partie newsletter du footer ( @media 0 -> 768 )
//$c_footer_subFooter: $black_grey; //Texte du lien IBS NETWORK du footer
//$bg_footer_subFooter: $white; //Fond du lien IBS NETWORK du footer
//
///* page liste des actualités */
//
//$bordc_listeActu_a: $white; //Bordure de l'actualité de la liste des actualités
//
///* page de contenau rédactionnel */
//
//$bordc_contenuR_titre: $orange; //Bordure des <h1> des pages de contenus rédactionnel
//$bg_contenuR: $white; //Fond du container des pages de contenus rédactionnel
//$bordc_contenuR_h1: $orange; //Bordure des <h1> des pages de contenus rédactionnel
//$c_contenuR_h1: $orange; //Texte des <h1> des pages de contenus rédactionnel
//$c_contenuR_h2: $purple; //Texte des <h2> des pages de contenus rédactionnel
//$c_contenuR_h3: $purple; //Texte des <h3> des pages de contenus rédactionnel
//$c_contenuR_h4: $purple; //Texte des <h4> des pages de contenus rédactionnel
//$c_contenuR_h5: $black_grey; //Texte des <h5> des pages de contenus rédactionnel
//$c_contenuR_h6: $black_grey; //Texte des <h6> des pages de contenus rédactionnel
//$c_contenuR_p: $black_grey; //Texte des <p> des pages de contenus rédactionnel
//$c_contenuR_p_strong: $purple; //Texte des <strong> des <p> des pages de contenus rédactionnel
//$c_contenuR_ol: $black_grey; //Texte des <ol> des pages de contenus rédactionnel
//$c_contenuR_ol_before: $orange; //Puce des <ol> des pages de contenus rédactionnel
//$c_contenuR_ul: $black_grey; //Texte des <ul> des pages de contenus rédactionnel
//$c_contenuR_a: $orange; //Liens des pages de contenus rédactionnel
//$c_contenuR_encart: $orange; //Texte des encarts de mise en valeur des pages de contenus rédactionnel
//$bordc_contenuR_encart: $pink; //Bordure des encarts de mise en valeur des pages de contenus rédactionnel
//$bordc_contenuR_table: $orange; //Bordure des tableaux des pages de contenus rédactionnel
//$bordc_contenuR_table_td: $orange; //Bordure des cellules des tableaux des pages de contenus rédactionnel
//$bg_contenuR_table_tr_1: $white; //Fond des lignes impairs des tableaux des pages de contenus rédactionnel
//$bg_contenuR_table_tr_2: $light_orange; //Fond des lignes pairs des tableaux des pages de contenus rédactionnel
//$bg_galerie: $purple; //Fond de la galerie
//$c_galerie_h1: $orange; //Titre de le galerie
//$c_galerie_h1_picto: $pink; //Picto du titre de le galerie
$bg_galerie_hover_filtre: $filtre; //Fond du filtre des images de la galerie au survol
$c_galerie_hover_filtre_i: $orange; //Picto du filtre des images de la galerie au survol
$bg_galerie_legende_hover: $orange; //Fond des images de la galerie au survol
//

/* page de contact */

$c_contact_h2: $black_blue; //Titre de la page de contact
$c_contact_label: $orange; //Texte des labels de la page de contact
//$c_contact_mention: $light_grey; //Texte de la mention du formulaire de la page de contact
//$bordc_contact_aside: $orange; //Bordure de l'aside la page de contact
//$c_contact_aside_mission: $orange; //Texte "Mission Locale de l'Agenais, de l'Albret et du Confluent" de l'aside la page de contact
//$c_contact_aside_adress: $purple; //Texte "70, Boulevard Sylvain Dumon 47000 AGEN" de l'aside la page de contact
//$c_contact_aside_tel: $orange; //Texte "Tél : 05 53 47 23 32" de l'aside la page de contact
//$c_contact_aside_mail_titre: $purple; //Texte "e-mail :" de l'aside la page de contact
//$c_contact_aside_mail: $orange; //Texte "siege@mission-locale-agen.org" de l'aside la page de contact
//$c_contact_aside_horaires: $purple; //Texte "Horaires d'Ouverture" de l'aside la page de contact
//$c_contact_aside_horaires_titre: $orange; //Texte des dates d'ouverture de l'aside la page de contact
//$c_contact_aside_horaires_heures: $purple; //Texte des heures d'ouverture de l'aside la page de contact

///* formulaire de connexion */
//
//$c_connexion_h1: $pink; //Titre "connexion" du formulaire du connexion
//$c_connexion_p: $white; //Texte "je ne suis pas inscrit" du formulaire du connexion
//$c_connexion_a: $orange; //Lien "je m'inscrit" du formulaire du connexion
//$bg_connexion_input: $pink; //Fond des input du formulaire de connexion
//$c_connexion_input: $white; //Texte des input du formulaire de connexion
//$c_connexion_input_placeholder: $white; //Texte des placeholder des input du formulaire de connexion
//$bg_connexion_button: $purple; //Fond du bouton du formulaire de connexion
//$c_connexion_button: $white; //Texte du bouton du formulaire de connexion
//$bordc_connexion_input: $purple; //Bordure du bouton du formulaire de connexion
//$bordc_connexion_input_hover: $white; //Bordure du bouton du formulaire de connexion au survol
//$bg_connexion_input_hover: $transparent; //Fond du bouton du formulaire de connexion au survol
//$c_connexion_input_hover: $white; //Fond du bouton du formulaire de connexion au survol
//$bordc_inscription_label_before_checked: $pink; //Bordure du before des radios actifs du formulaire d'inscription
//$bordc_inscription_label_before: $white; //Bordure du before des radios du formulaire d'inscription
//$bg_inscription_label_before: $white; //Fond du before des radios du formulaire d'inscription
//$bg_inscription_label_after: $pink; //Fond du after des radios du formulaire d'inscription
//$c_inscription_titre: $white; //Texte "situation professionnelle" du formulaire d'inscription
//
///* Liste des modules */
//
//$bg_emplois_a: $white; //Fond des liens de la page d'accueil dédiée des services
//$bg_emplois_a_hover: $orange; //Fond des liens de la page d'accueil dédiée des services au survol
//$bordc_emplois_a: $orange; //Bordure des liens de la page d'accueil dédiée des services
//$bg_emplois_fond: $orange; //Fond des pictos de la page d'accueil dédiée des services
//$bg_emplois_fond_hover: $white; //Fond des pictos de la page d'accueil dédiée des services au survol
//$c_emplois_i: $white; //Pictos de la page d'accueil dédiée des services
//$c_emplois_i_hover: $orange; //Pictos de la page d'accueil dédiée des services au survol
//$c_emplois_h2: $purple; //Pictos de la page d'accueil dédiée des services
//$c_emplois_h2_hover: $white; //Pictos de la page d'accueil dédiée des services au survol
//
///* liste annonces */
//
//$c_listAnnonce_h1_picto: $orange; //Picto du titre des pages de liste d'annonces
//$bordc_listAnnonce_annonce: $orange; //Bordure du bas d'une annonce des pages de liste d'annonces
//$c_listAnnonce_h3: $pink; //Texte du titre des annonces de liste d'annonces
//$c_listAnnonce_categorie: $purple; //Texte de la catégorie des annonces de liste d'annonces
//$c_listAnnonce_ajout: $light_grey; //Texte de la date de l'annonce des pages de liste d'annonces
//$c_listAnnonce_h3_new: $orange; //Texte "nouveau" des listes d'annonces
//$c_listAnnonce_h3_pdf: $red; //Picto pdf des listes de documents
//$c_listAnnonce_placeRestantes_full: $red; //Texte "places restantes" des listes d'annonces
//$bg_listAnnonce_annonce_full: $smooth_grey; //Fond des annonces quand nb places restantes = 0 dans listes d'annonces
//$c_listAnnonce_p_full: $smooth_grey_txt; //Texte des annonces quand nb places restantes = 0 dans listes d'annonces
//$c_btnFull:$smooth_grey; //Texte du bouton quand nb places restantes = 0 dans listes d'annonces
//$c_btnFull_hover:$smooth_grey_txt; //Texte du bouton quand nb places restantes = 0 dans listes d'annonces au survol
//$c_btnFull_active:$smooth_grey; //Texte du bouton quand nb places restantes = 0 dans listes d'annonces actif
//$bordc_btnFull: $smooth_grey_txt; //Bordure du bouton quand nb places restantes = 0 dans listes d'annonces
//$bordc_btnFull_hover: $smooth_grey_txt; //Bordure du bouton quand nb places restantes = 0 dans listes d'annonces au survol
//$bordc_btnFull_active: $smooth_grey_txt; //Bordure du bouton quand nb places restantes = 0 dans listes d'annonces actif
//$bg_btnFull: $smooth_grey_txt; //Fond du bouton quand nb places restantes = 0 dans listes d'annonces
//$bg_btnFull_hover: $smooth_grey; //Fond du bouton quand nb places restantes = 0 dans listes d'annonces au survol
//$bg_btnFull_active: $smooth_grey_txt; //Fond du bouton quand nb places restantes = 0 dans listes d'annonces actif
//$c_listAnnonce_poids: $light_grey; //Texte du poids du fichier dans la liste des documents
//
///* bouton s'inscrire quand nb places restantes = 0 dans listes d'annonces actif */
//
//$bg_btnFull_alert: $red_noPlace; //Fond du collapse
//$c_btnFull_alert: $black_grey; //Texte du collapse
//
///* bouton s'inscrire pour prendre rendez-vous */
//
//$bg_btn_well: $orange; //Fond du collapse pour prendre rendez-vous
//
///* menu des services */
//
//$bg_services_a: $orange; //Fond du block des services dans le menu des services
//$c_services_i: $white; //Picto du block des services dans le menu des services
//$c_services_h3: $white; //Texte du block des services dans le menu des services
//$bg_services_a_hover: $white; //Fond du block des services dans le menu des services au survol
//$c_services_i_hover: $orange; //Picto du block des services dans le menu des services au survol
//$c_services_h3_hover: $orange; //Texte du block des services dans le menu des services au survol
//$bg_services_rdv_a: $purple; //Fond du block prise de rendez-vous des services dans le menu des services
//$bg_services_rdv_a_hover: $white; //Fond du block prise de rendez-vous des services dans le menu des services au survol
//$c_services_rdv_i_hover: $purple; //Picto du block prise de rendez-vous des services dans le menu des services au survol
//$c_services_rdv_h3_hover: $purple; //Texte du block prise de rendez-vous des services dans le menu des services au survol
//
///* Form rdv conseiller */
//
//$c_rdv_h5: #595959; //Texte du titre "étape 1..." des formulaire de rendez-vous avec un conseiller
//$bordc_rdv_block: #efefef; //Bordure des blocks des formulaire de rendez-vous avec un conseiller
//$bg_rdv_block: #fdfdfd; //Fond des blocks des formulaire de rendez-vous avec un conseiller
//$bg_rdv_btn_nonDispo: #ebebeb; //Fond des button non-dispo des formulaire de rendez-vous avec un conseiller
//$bordc_rdv_btn_nonDispo: #ebebeb; //Bordure des button non-dispo des formulaire de rendez-vous avec un conseiller
//$c_rdv_btn_nonDispo: #bebebe; //Texte des button non-dispo des formulaire de rendez-vous avec un conseiller
//
///* background-image */

/* css général */

$bgImg_separateur: url(../images/points_couleur.png); //Séparateur

$bgImg_degrade: url(../images/degrade_background.png); //Séparateur

/* header */

$bgImg_header: url(../images/carre_couleur_topmenu.png); //Fond du header
$bgImg_header_ul: url(../images/carre_couleur_ulmenu.png); //Fond du ul du header

/* home page */


/* services */

$bgImg_services: url(../images/ombre.png); //Ombre du bouton des services de la page d'accueil
$bgImg_services2: url(../images/ombre02.png); //Ombre2 du bouton des services de la page d'accueil
$bgImg_bottom_liste_item: url(../images/bottom_liste_item.png); //Liseré des items des listes
$bgImg_services_rdv: url(../images/bande_carre.png); //Bande du bouton rdv des services de la page d'accueil
$bgImg_services_ema: url(../images/bande_ema.png); //Bande dans bloc EMA des services de la page d'accueil


/* slider */
$bgImg_slider_nav_li_left: url("../images/trame_g_slide.png");//Fond du bord gauche du slider de la page d'accueil
$bgImg_slider_nav_li_right: url("../images/trame_d_slide.png");//Fond du bord droit du slider de la page d'accueil
$bgImg_slider_nav: url("../images/sprite_slider_nav.png");//Bouton de navigation du slider de la page d'accueil
$bgImg_slider_nav_hover: url("../images/sprite_slider_nav_hover.png");//Bouton de navigation du slider de la page d'accueil au survol

/* actualités home */
$bgImg_actus_home_plus: url("../images/plus.png");//Plus des actu de la page d'accueil au survol

/* Offres home */
$bgImg_offre_home_nav_left: url("../images/flechG_small.png");//Bouton gauche de navigation du slider des offres de la page d'accueil
$bgImg_offre_home_nav_left_hover: url("../images/flechG2_small.png");//Bouton gauche de navigation du slider des offres de la page d'accueil au survol
$bgImg_offre_home_nav_right: url("../images/flechD2_small.png");//Bouton gauche de navigation du slider des offres de la page d'accueil
$bgImg_offre_home_nav_right_hover: url("../images/flechD_small.png");//Bouton gauche de navigation du slider des offres de la page d'accueil au survol
$bgImg_offre_home_nav_points: url("../images/points_gris.png");//Bouton gauche de navigation du slider des offres de la page d'accueil





/* footer */

$bgImg_footer_ul: url(../images/puce_footer.png); //Puce du footer

/* head */


/* break media queries */

$media_1200: "screen and (min-width:1200px)";
$media_768: "screen and (min-width:768px)";
$media_992_1199: "screen and (min-width:992px) and (max-width:1199px)";
$media_768_991: "screen and (min-width:768px) and (max-width:991px)";
$media_480_767: "screen and (min-width:480px) and (max-width:767px)";
$media_320_479: "screen and (min-width:320px) and (max-width:479px)";
$media_0_1199: "screen and (max-width:1199px)";
$media_0_991: "screen and (max-width:991px)";
$media_0_767: "screen and (max-width:767px)";
$media_0_500: "screen and (max-width:500px)";
$media_0_359: "screen and (max-width:359px)";
$media_0_319: "screen and (max-width:319px)";

/* mixins */

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}


/* pour centrer verticalement */

@mixin display-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin flex-direction {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

@mixin flex-direction-row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

@mixin flex-direction-between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@mixin justify-content($justification) {
	-webkit-box-pack: $justification;
	-webkit-justify-content: $justification;
	-ms-flex-pack: $justification;
	justify-content: $justification;
}

@mixin justify-content-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@mixin justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

@mixin align-items($align) {
	-webkit-box-align: $align;
	-webkit-align-items: $align;
	-ms-flex-align: $align;
	align-items: $align;
}

@mixin flex-wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@mixin column($nb_column, $column_gap) {
	/* nb column */
	-webkit-column-count: $nb_column;
	/* Chrome, Safari, Opera */
	-moz-column-count: $nb_column;
	/* Firefox */
	column-count: $nb_column;
	/* space beetween column */
	-webkit-column-gap: $column_gap;
	/* Chrome, Safari, Opera */
	-moz-column-gap: $column_gap;
	/* Firefox */
	column-gap: $column_gap;
}

@mixin degrade_accueil_services {
background: #f1f1f1; /* Old browsers */
background: -moz-linear-gradient(top, #f1f1f1 0%, #c4c4c4 64%, #dddddd 71%, #ffffff 83%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #f1f1f1 0%,#c4c4c4 64%,#dddddd 71%,#ffffff 83%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #f1f1f1 0%,#c4c4c4 64%,#dddddd 71%,#ffffff 83%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f1f1', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

@mixin degrade_file_btn{
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 76%, #e5e5e5 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #ffffff 0%,#f2f2f2 76%,#e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #ffffff 0%,#f2f2f2 76%,#e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
}


@mixin transition($args){
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}

/* animations mixins */

//animation slideUp
@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
		opacity: 0;
	}
	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

//animation slideUp
@keyframes triangle {
	from {
		width: 49px;
	}
	to {
		width: auto;
	}
}

///* variable for select */
//
//$background: #cc62c2;
//$select-color: #fff;
//$select-background: #cc62c2;
//$select-width: 100%;
//$select-height: 34px;